.microsoft{
   
    margin-bottom: 30px;
}

.microsoft-logo{
    max-width: 50%;
}

.imap{
    

    

    
}

.imap-logo{
    max-width: 25%;

}