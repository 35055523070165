.tabs{
    font-family:arial;
    margin:0 auto;
    width:70%;
    text-align:center;
    margin-top:15vh;
  }
  
  img{
    width:300px;
  }
  
  /* .tab-buttons{
    margin-bottom:50px;
    color : #fff;
  } */
  
  button{
    margin-right:15px;
    font-size:20px;
    background:transparent;
    border:none;
    outline:none;
    padding:10px 20px ;
    cursor:pointer;
    /* color:rgba(0,0,0,.4); */
    transition:all ease-in-out .2s;
    /* border-bottom:2px solid transparent; */
    
  }
  .tab-group.tab-group-switch-style > ul li {
    padding: 8px 20px;
    border: none;
    border-radius: 22px;
    background-color: #fff;
}
  .tab-group.tab-group-switch-style > ul li.active {
    color: #fff;
    background-color: #0098ef;
}
   .active{
    border-bottom:2px solid #42b3f4; 
    color: #fff;
     background-color: #0098ef; 
  }


