.logos{
    max-width: 70%;
}

.large-logos{
    max-width: 20%;
}

.location{
  
    margin-bottom: 60px;
}