

.R-Form{
    text-align: center;
   
    padding-top:1vh;

    background-color: #0287d4;
    font-size: 25px;
    font-weight: bolder;
    color: white;
   
}
form {
   
    padding: 10px;
  }

  input{
    color: black;
    text-align: center;
  }

  .section{
   
    margin-bottom: 2vh;
  }

  .title{
     font-size: 50px;
     margin-bottom: 4vh;
  }

  .submit{
    width: 30vh;
    border: 1px solid white;
    background-color: #0098ef !important;
    color: white !important;
    font-weight: bolder;
  }

  .checkbox{
    width: 30px;
    height: 20px
  }