/*Fonts*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700italic,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 120px;
	color: #4a5669;
	line-height: 1;
	font-size: 16px;
	font-weight: normal;
	font-family: "Open Sans", sans-serif;
	background-color: #fff;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

