@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700italic,700);

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,
video{
    margin:0;
    padding:0;
    border:0;
    font-size:100%;
    font:inherit;
    vertical-align:baseline
}

article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,
summary{
    display:block
}

audio,canvas,video{
    display:inline-block
}

audio:not([controls]){
    display:none;
    height:0
}
[hidden]{
    display:none
}

html{
    font-family:sans-serif;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%
}

body{
    margin:0
}

a:focus{
    outline:thin dotted
}

a:active,a:hover{
    outline:0
}

h1{
    font-size:2em;
    margin:0
}

abbr[title]{
    border-bottom:1px dotted
}

b,
strong{
    font-weight:bold
}

dfn{
    font-style:italic
}

hr{
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    height:0
}

mark{
    background:#ff0;
    color:#000
}

code,kbd,pre,
samp{
    font-family:monospace,serif;
    font-size:1em
}

pre{
    white-space:pre-wrap
}

q{
    quotes:"\201C" "\201D" "\2018" "\2019"
}

small{
    font-size:80%
}

sub,sup{
    font-size:75%;
    line-height:0;
    position:relative;
    vertical-align:baseline
}

sup{
    top:-0.5em
}
sub{
    bottom:-0.25em
}
img{
    border:0
}
svg:not(:root){
    overflow:hidden
}

figure{
    margin:0
}
fieldset{
    border:1px solid silver;
    margin:0 2px;
    padding:.35em .625em .75em
}

legend{
    border:0;
    padding:0
}
button,input,select,textarea{
    font-family:inherit;
    font-size:100%;
    margin:0
}

button,input{
    line-height:normal
}

button,select{
    text-transform:none
}

button,html input[type="button"],input[type="reset"],input[type="submit"]{
    -webkit-appearance:button;
    cursor:pointer
}

button[disabled],html input[disabled]{
    cursor:default
}
input[type="checkbox"],input[type="radio"]{
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    padding:0
}
input[type="search"]{
    -webkit-appearance:textfield;
    -webkit-box-sizing:content-box;
    box-sizing:content-box
}
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{
    -webkit-appearance:none
}
button::-moz-focus-inner,input::-moz-focus-inner{
    border:0;
    padding:0
}
textarea{
    overflow:auto;
    vertical-align:top
}
table{
    border-collapse:collapse;
    border-spacing:0
}
textarea{
    resize:vertical
}
.clearfix::after{
    content:"";
    display:table;
    clear:both
}
body{
    padding-top:120px;
    color:#4a5669;
    line-height:1;
    font-size:16px;
    font-weight:normal;
    font-family:"Open Sans",sans-serif;
    background-color:#fff;
    -webkit-font-smoothing:antialiased
}
body::after{
    overflow:hidden;
    position:absolute;
    width:0;
    height:0;
    font-family:"Material Icons";
    content:"\e86f"
}
body.preload::before{
    content:'';
    position:fixed;
    z-index:1100;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:#0098ef;
    -webkit-transition:opacity .4s;
    transition:opacity .4s
}
body.preload::after{
    content:'';
    position:fixed;
    z-index:1101;
    top:50%;
    left:50%;
    width:32px;
    height:32px;
    margin:-16px 0 0 -16px;
    background:transparent url(./logo.svg) center center no-repeat;
    -webkit-animation:body-preload .6s linear infinite;
    animation:body-preload .6s linear infinite
}
body.preload.preload-done::before,body.preload.preload-done::after{
    opacity:0
}
@-webkit-keyframes body-preload{
    0%{
    -webkit-transform:rotate(0);
    transform:rotate(0)
}
100%{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
}

}
@keyframes body-preload{
    0%{
    -webkit-transform:rotate(0);
    transform:rotate(0)
}
100%{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
}

}
input:not(.no-custom-style):not([type="checkbox"]):not([type="radio"]),select:not(.no-custom-style),textarea:not(.no-custom-style),button{
    display:inline-block;
    line-height:24px;
    padding:0;
    margin:0;
    outline:0;
    border:0;
    -webkit-box-shadow:none;
    box-shadow:none;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none
}
input:not(.no-custom-style):not([type="checkbox"]):not([type="radio"]),select:not(.no-custom-style),textarea:not(.no-custom-style){
    color:#4a5669;
    padding:12px 15px;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:6px;
    -webkit-transition:color .2s,border-color .2s;
    transition:color .2s,border-color .2s
}
input:not(.no-custom-style):not([type="checkbox"]):not([type="radio"]):focus,select:not(.no-custom-style):focus,textarea:not(.no-custom-style):focus{
    border-color:#0098ef
}
input:not(.no-custom-style):not([type="checkbox"]):not([type="radio"]):disabled,select:not(.no-custom-style):disabled,textarea:not(.no-custom-style):disabled,button:disabled{
    cursor:not-allowed;
    color:#a0abbc!important;
    background:#f0f2f5!important
}
a{
    color:#0098ef;
    text-decoration:none;
    -webkit-transition:color .2s,background-color .2s;
    transition:color .2s,background-color .2s
}
a:focus,a:active{
    outline:0
}
a:hover{
    text-decoration:underline
}
h1,h2,h3,h4,h5{
    font-family:Montserrat,sans-serif;
    font-weight:normal
}
h1{
    font-size:42px;
    line-height:52px
}
h2{
    font-size:32px;
    line-height:42px
}
h3{
    font-size:24px;
    line-height:34px
}
h4{
    font-size:20px;
    line-height:30px
}
h5{
    font-size:16px;
    line-height:26px
}
p{
    line-height:28px
}
small{
    font-size:87.5%;
    line-height:87.5%
}

em{
    font-style:italic
}
strong{
    font-weight:bold
}
mark{
    color:#4a5669;
    padding:0 .2em;
    background-color:#fc3;
    border-radius:.2em
}
abbr{
    cursor:help
}
address{
    line-height:28px
}
blockquote{
    position:relative;
    padding:20px 0 20px 30px
}
blockquote::before{
    position:absolute;
    content:"";
    top:0;
    left:0;
    width:30px;
    height:100%;
    border:2px solid #e6e9ee;
    border-right:0;
    border-top-left-radius:12px;
    border-bottom-left-radius:12px
}
blockquote footer{
    margin-top:15px;
    color:#a0abbc;
    font-size:14px;
    line-height:26px
}
blockquote cite{
    font-style:italic
}
hr{
    margin:0;
    border:0;
    border-top:2px solid #e6e9ee
}
dl{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
    -ms-flex-flow:row;
    flex-flow:row;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    line-height:28px
}
dl dt{
    -webkit-box-flex:0;
    -ms-flex:0 0 30%;
    flex:0 0 30%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    color:#a0abbc
}
dl dd{
    -webkit-box-flex:0;
    -ms-flex:0 0 70%;
    flex:0 0 70%;
    padding-left:10px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
dl dt:not(:first-of-type),dl dd:not(:first-of-type){
    margin-top:20px
}
img,video,iframe{
    display:inline-block;
    max-width:100%
}
.text-margin-left{
    margin-left:1em
}
.text-margin-right{
    margin-right:1em
}
.text-align-left{
    text-align:left
}
.text-align-right{
    text-align:right
}
.text-align-center{
    text-align:center
}
.text-break-all{
    word-break:break-all
}
.text-color-dark{
    color:#4a5669!important
}
.text-color-light{
    color:#fff!important
}
.text-color-gray{
    color:#a0abbc!important
}
.text-color-primary{
    color:#0098ef!important
}
.text-color-secondary{
    color:#fc3!important
}
.text-color-success{
    color:#00b75a!important
}
.text-color-warning{
    color:#ffcc34!important
}
.text-color-error{
    color:#cd3100!important
}
.type-text.type-text-cursor-none::after{
    display:none
}
.type-text.type-text-cursor-underscore::after{
    content:"_"
}
.type-text:not(.type-text-cursor-underscore)::after{
    content:"|";
    -webkit-animation:type-text-cursor .8s infinite;
    animation:type-text-cursor .8s infinite
}
@-webkit-keyframes type-text-cursor{
    0%{
    opacity:1
}
50%{
    opacity:0
}
100%{
    opacity:1
}

}
@keyframes type-text-cursor{
    0%{
    opacity:1
}
50%{
    opacity:0
}
100%{
    opacity:1
}

}
ul.list-style-nav,ul.list-style-none,ul.list-style-icon{
    list-style-type:none
}
ul.list-style-nav{
    overflow:hidden;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:12px
}
ul.list-style-nav>li{
    -webkit-transition:background-color .2s;
    transition:background-color .2s
}
ul.list-style-nav>li:not(:last-of-type){
    border-bottom:1px solid #e6e9ee
}
ul.list-style-nav>li:hover{
    background-color:#f0f2f5
}
ul.list-style-nav>li a:hover{
    color:#a0abbc!important;
    text-decoration:none
}
ul.list-style-nav>li>a{
    display:block;
    padding:10px 15px
}
ul.list-style-nav>li ul{
    margin:8px 0 24px 34px!important;
    list-style-type:none;
    font-size:14px
}
ul.list-style-icon li{
    position:relative;
    padding-left:26px
}
ul.list-style-icon li .fa,ul.list-style-icon li .fas,ul.list-style-icon li .far,ul.list-style-icon li .fal,ul.list-style-icon li .fab,ul.list-style-icon li .material-icons{
    position:absolute;
    left:0;
    width:20px;
    height:28px;
    line-height:28px;
    text-align:center
}
ul.list-style-icon li .svg-inline--fa{
    position:absolute;
    top:6px;
    left:0;
    width:20px
}
table{
    position:relative;
    overflow:hidden;
    width:100%;
    max-width:100%;
    color:#4a5669;
    text-align:center;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-collapse:separate;
    border-radius:12px
}
table.table-layout-fixed{
    table-layout:fixed
}
table.table-has-caption{
    border-top:0;
    border-top-left-radius:0;
    border-top-right-radius:0
}
table caption{
    padding:15px;
    color:#fff;
    font-size:14px;
    line-height:24px;
    text-transform:uppercase;
    font-family:Montserrat,sans-serif;
    background-color:#0098ef;
    border-top-left-radius:12px;
    border-top-right-radius:12px
}
table tr td,table tr th{
    padding:15px;
    line-height:24px;
    vertical-align:middle;
    border-bottom:1px solid #e6e9ee
}
table thead tr th{
    color:#a0abbc;
    background-color:#f0f2f5;
    border-bottom:2px solid #e6e9ee
}
table tr:last-of-type:not(:first-of-type) td,table tr:last-of-type:not(:first-of-type) th{
    border-bottom:0
}
table tfoot tr td,table tfoot tr th{
    border:0
}
table tfoot tr:first-of-type td,table tfoot tr:first-of-type th{
    border-top:2px solid #e6e9ee
}
table tr td button,table tr th button,table tr td .button,table tr th .button{
    width:100%
}
table tr .highlight{
    color:#0098ef;
    font-size:20px;
    font-family:Montserrat,sans-serif
}
table tr .responsive-th{
    display:none;
    overflow:hidden;
    position:absolute;
    left:4%;
    width:44%;
    color:#a0abbc;
    font-size:16px;
    font-family:"Open Sans",sans-serif;
    text-overflow:ellipsis;
    text-align:right;
    white-space:nowrap
}
table.table-layout-domain tr td:nth-of-type(1){
    width:10%
}
table.table-layout-domain tr td:nth-of-type(2){
    width:50%;
    text-align:left;
    word-break:break-all
}
table.table-layout-domain tr td:nth-of-type(3),table.table-layout-domain tr td:nth-of-type(4){
    width:20%
}
table.table-layout-product-comparison{
    table-layout:fixed
}
table.table-layout-product-comparison thead{
    display:none
}
table.table-layout-product-comparison tr:not(.product-feature-group) td:first-of-type{
    color:#a0abbc
}
table.table-layout-product-comparison tr.product-header td{
    padding-top:30px;
    padding-bottom:30px
}
table.table-layout-product-comparison tr.product-header td h4{
    margin-top:0!important
}
table.table-layout-product-comparison tr.product-header td p:not(.product-price){
    margin-top:0!important;
    color:#a0abbc;
    font-size:14px;
    line-height:24px
}
table.table-layout-product-comparison tr.product-header td p.product-price{
    position:relative;
    color:#0098ef;
    font-size:32px;
    font-weight:bold
}
table.table-layout-product-comparison tr.product-header td p.product-price .term{
    position:absolute;
    bottom:-8px;
    margin-left:4px;
    font-size:12px
}
table.table-layout-product-comparison tr.product-order td{
    padding-top:30px;
    padding-bottom:30px
}
table.table-layout-product-comparison tr.product-order td button,table.table-layout-product-comparison tr.product-order td .button{
    width:auto
}
table.table-layout-product-comparison tr.product-feature-group td{
    background-color:#f0f2f5
}
form{
    position:relative;
    width:100%
}

form label{
    cursor:pointer;
    line-height:28px;
    padding:0 15px 0 15px;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
form label:first-child{
    display:inline-block
}
form fieldset{
    padding:30px;
    border:2px solid #e6e9ee;
    border-radius:12px
}
form fieldset legend{
    padding:0 15px;
    font-size:14px;
    font-weight:bold;
    font-family:Montserrat,sans-serif;
    text-transform:uppercase
}
form input[type="file"]{
    line-height:1
}
form input[type="checkbox"],form input[type="radio"]{
    margin-left:15px;
    margin-right:-5px;
    cursor:pointer
}
form textarea{
    resize:vertical
}
form.form-full-width input:not(.no-custom-style):not([type="checkbox"]):not([type="radio"]),form.form-full-width select:not(.no-custom-style),form.form-full-width textarea:not(.no-custom-style){
    width:100%
}
form .form-row{
    position:relative
}
form .form-row:not(:first-child){
    margin-top:30px
}
form .form-row.form-success,form .form-row.form-error{
    padding:12px 15px;
    color:#fff;
    text-align:center;
    background-color:#00b75a;
    border-radius:6px
}
form .form-row.form-error{
    background-color:#cd3100
}
form .form-row.form-success ul,form .form-row.form-error ul{
    margin:0!important;
    list-style-type:none
}
form .checkbox-style,form .radio-style{
    display:inline-block;
    position:relative;
    overflow:hidden;
    width:16px;
    height:16px;
    text-align:left;
    margin:-2px -2px 0 15px;
    vertical-align:middle;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:4px;
    -webkit-transition:border-color .2s;
    transition:border-color .2s
}
form .radio-style{
    border-radius:10px
}
form .checkbox-style.focus,form .radio-style.focus{
    border-color:#0098ef
}
form .checkbox-style.disabled,form .radio-style.disabled{
    background-color:#f0f2f5
}
form .checkbox-style i,form .checkbox-style .checkbox-style-icon,form .radio-style .radio-style-icon{
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    width:16px;
    height:16px;
    line-height:16px;
    text-align:center;
    font-size:14px;
    color:#4a5669;
    cursor:pointer
}
form .checkbox-style .svg-inline--fa{
    width:12px
}
form .radio-style .radio-style-icon::after{
    content:"";
    position:absolute;
    top:4px;
    left:4px;
    width:8px;
    height:8px;
    background-color:#4a5669;
    border-radius:4px
}
form .checkbox-style.disabled i,form .checkbox-style.disabled .svg-inline--fa,form .radio-style.disabled .radio-style-icon{
    color:#a0abbc;
    cursor:not-allowed
}
form .checkbox-style:not(.active) i,form .checkbox-style:not(.active) .svg-inline--fa,form .radio-style:not(.active) .radio-style-icon{
    opacity:0
}
form .checkbox-style input[type="checkbox"],form .radio-style input[type="radio"]{
    position:absolute;
    top:0;
    left:0;
    margin:0;
    padding:0;
    opacity:0;
    border:0;
    width:16px;
    height:16px
}
form .checkbox-style-block .checkbox-style,form .radio-style-block .radio-style{
    position:absolute;
    top:5px;
    left:15px;
    margin:0
}
form .checkbox-style-block label,form .radio-style-block label{
    display:inline-block;
    padding-left:52px
}
form .select-style,form .search-style{
    display:inline-block;
    position:relative
}
form.form-full-width .select-style,form.form-full-width .search-style{
    width:100%
}
form .select-style i,form .search-style i{
    position:absolute;
    top:10px;
    right:10px;
    width:32px;
    height:32px;
    line-height:32px;
    text-align:center;
    cursor:pointer;
    color:#a0abbc
}
form .select-style .svg-inline--fa,form .search-style .svg-inline--fa{
    position:absolute;
    top:18px;
    right:10px;
    width:32px;
    cursor:pointer;
    color:#a0abbc
}
form .select-style .svg-inline--fa{
    top:19px
}
form .select-style input,form .search-style input{
    padding-right:48px!important
}
form .select-style input.focus{
    border-color:#0098ef
}
form .select-style select{
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    opacity:0;
    width:100%;
    cursor:pointer
}
form .select-style select:disabled{
    cursor:not-allowed
}
button,
.button{
    display:inline-block;
    padding:16px 20px;
    cursor:pointer;
    color:#0098ef;
    line-height:1;
    font-size:14px;
    font-weight:bold;
    font-family:Montserrat,sans-serif;
    text-transform:uppercase;
    text-align:center;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:6px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    -webkit-transition:color .2s;
    transition:color .2s
}
button.button-primary,
.button.button-primary,button.button-secondary,
.button.button-secondary{
    padding:18px 22px;
    border:0
}
button.button-primary,
.button.button-primary{
    color:#fff;
    background-color:#0098ef
}
button.button-secondary,
.button.button-secondary{
    color:#4a5669;
    background-color:#fc3
}
button.button-small,
.button.button-small{
    padding:8px 14px;
    border-radius:4px
}
button.button-primary.button-small,
.button.button-primary.button-small,button.button-secondary.button-small,
.button.button-secondary.button-small{
    padding:10px 16px
}
button.button-large,
.button.button-large{
    font-size:16px;
    padding:20px 24px;
    border-radius:8px
}
button.button-primary.button-large,
.button.button-primary.button-large,button.button-secondary.button-large,
.button.button-secondary.button-large{
    padding:22px 26px
}
button.button-full-width,
.button.button-full-width{
    width:100%
}
button:hover,
.button:hover{
    color:#a0abbc;
    text-decoration:none
}
button.button-primary:hover,
.button.button-primary:hover{
    color:rgba(255,255,255,0.6)
}
button.button-secondary:hover,
.button.button-secondary:hover{
    color:rgba(74,86,105,0.6)
}
button:focus,
.button:focus,button:active,
.button:active{
    background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(74,86,105,0.1)),to(rgba(74,86,105,0.1)));
    background-image:linear-gradient(rgba(74,86,105,0.1) 0,rgba(74,86,105,0.1) 100%)
}
code{
    padding:2px 6px;
    font-family:Courier,monospace;
    background-color:rgba(74,86,105,0.1);
    border-radius:3px
}
pre{
    overflow:auto;
    padding:14px 20px;
    color:#4a5669;
    -moz-tab-size:4;
    -o-tab-size:4;
    tab-size:4;
    white-space:pre;
    font:normal 14px/20px Courier,monospace;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:12px
}
.pre-code{
    position:relative;
    overflow:hidden;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:12px
}
.pre-code ul{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    width:16px;
    padding:14px 10px;
    margin:0!important;
    list-style-type:none;
    background-color:#f0f2f5;
    border-right:2px solid #e6e9ee;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}

.pre-code ul li{
    color:#a0abbc;
    font-size:12px;
    line-height:20px;
    text-align:right
}

.pre-code pre{
    margin-left:38px;
    background:0;
    border:0;
    border-radius:0
}

ul.tags{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    margin-left:0!important;
    margin-bottom:-8px!important;
    list-style-type:none
}
ul.tags.align-center{
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
ul.tags.align-left{
    -webkit-box-pack:start;
    -ms-flex-pack:start;
    justify-content:flex-start
}
ul.tags.align-right{
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end
}
ul.tags li{
    margin:0 8px 8px 0
}
ul.tags li a{
    display:block;
    color:#fff;
    padding:4px 8px;
    line-height:20px;
    font-size:14px;
    white-space:nowrap;
    font-family:Montserrat,sans-serif;
    background-color:#0098ef;
    border-radius:4px
}
ul.tags li a:hover{
    color:rgba(255,255,255,0.6);
    text-decoration:none
}
.material-icons{
    font-size:inherit;
    line-height:inherit;
    vertical-align:top
}
.icon-left,
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left{
    margin-right:.5em
}
.icon-right,
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right{
    margin-left:.5em
}
.icon-feature{
    height:64px;
    line-height:64px;
    font-size:64px;
    color: #0098ef;
    
}
.icon-feature-large{
    height:256px;
    line-height:256px;
    font-size:256px;
    color:#0098ef;
    text-shadow:8px 8px 0 rgba(74,86,105,0.1)
}
svg.icon-feature-large{
    -webkit-filter:drop-shadow(8px 8px 0 rgba(74,86,105,0.1));
    filter:drop-shadow(8px 8px 0 rgba(74,86,105,0.1))
}
.icon-feature-inline{
    margin-top:-4px;
    margin-right:12px;
    vertical-align:middle;
    font-size:150%;
    color:#0098ef
}
.feature-box{
    position:relative;
    display:block
}
.feature-box:hover{
    text-decoration:none
}
.feature-box .feature-header{
    position:relative;
    overflow:hidden;
    background-color:#e6e9ee;
    border-top-left-radius:12px;
    border-top-right-radius:12px
}
.feature-box .feature-header:only-child{
    border-radius:12px
}
.feature-box .feature-header svg,
.feature-box .feature-header img,
.feature-box .feature-header video,
.feature-box .feature-header iframe{
    display:block;
    width:100%
}
.feature-box .feature-header .icon-feature,
.feature-box .feature-header .icon-feature-large{
    display:block;
    padding:30px 0;
    text-align:center
}
.feature-box .feature-header .gallery:not(.gallery-slider) ul{
    margin:0!important
}
.feature-box .feature-header .gallery:not(.gallery-slider) ul li{
    padding:0
}
.feature-box .feature-header .gallery:not(.gallery-slider) ul li img,
.feature-box .feature-header .gallery.gallery-slider{
    border-radius:0
}
.feature-box .feature-header .feature-header-title,
.feature-box .feature-header .feature-header-title-hover{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding:30px;
    text-decoration:none;
    color:#fff!important;
    background-repeat:repeat-x;
    background-position:center top;
    background-image:-webkit-gradient(linear,left top,left bottom,color-stop(50%,rgba(44,54,69,0.8)),to(rgba(44,54,69,0.4)));
    background-image:linear-gradient(rgba(44,54,69,0.8) 50%,rgba(44,54,69,0.4) 100%)
}
.feature-box .feature-header .feature-header-title-hover{
    opacity:0;
    -webkit-transition:opacity .3s;
    transition:opacity .3s
}
.feature-box .feature-header .feature-header-title-hover:hover{
    opacity:1
}
.feature-box .feature-header .feature-header-title p,
.feature-box .feature-header .feature-header-title-hover p{
    font-size:14px;
    line-height:24px
}
.feature-box .feature-header .feature-header-title p:not(:first-child),
.feature-box .feature-header .feature-header-title-hover p:not(:first-child){
    margin-top:12px!important
}
.feature-box .feature-content{
    padding:28px;
    color:#4a5669;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-top-width:0;
    border-bottom-left-radius:12px;
    border-bottom-right-radius:12px
}
.feature-box .feature-content:only-child{
    border-top-width:2px;
    border-radius:12px
}
.feature-box.feature-box-no-border-radius .feature-header,
.feature-box.feature-box-no-border-radius .feature-content{
    border-radius:0
}
.product-box{
    position:relative;
    padding:30px 0;
    color:#4a5669;
    text-align:center;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:12px
}
.product-box.product-box-popular{
    margin-top:54px;
    border-color:#0098ef;
    border-top:0;
    border-top-left-radius:0;
    border-top-right-radius:0
}
.product-box .product-popular{
    position:absolute;
    top:-54px;
    left:-2px;
    right:-2px;
    height:54px;
    color:#fff;
    font-size:14px;
    line-height:54px;
    text-transform:uppercase;
    font-family:Montserrat,sans-serif;
    background-color:#0098ef;
    border-top-left-radius:12px;
    border-top-right-radius:12px
}
.product-box .product-header{
    padding:0 30px
}
.product-box .product-header p{
    margin-top:0!important;
    color:#a0abbc;
    font-size:14px;
    line-height:24px
}
.product-box .product-price{
    position:relative;
    padding:0 30px 30px 30px;
    margin-top:-6px;
    color:#0098ef;
    font-size:42px;
    font-weight:bold;
    border-bottom:1px solid #e6e9ee
}
.product-box .product-price .term{
    position:absolute;
    bottom:34px;
    margin-left:4px;
    font-size:13px
}
.product-box .product-features{
    padding:0 30px
}
.product-box .product-features ul{
    margin:0!important;
    list-style-type:none
}
.product-box .product-features ul:last-of-type:not(:first-of-type){
    margin-top:20px!important;
    color:#a0abbc;
    font-size:14px;
    line-height:24px
}
.product-box .product-order{
    padding:0 30px
}
.product-box .product-order .button{
    width:100%;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.product-box .product-order button:not(:first-child),
.product-box .product-order .button:not(:first-child){
    margin-top:15px
}
.product-box .product-header:not(:last-of-type),
.product-box .product-price:not(:last-of-type),
.product-box .product-features:not(:last-of-type),
.product-box .product-order:not(:last-of-type){
    margin-bottom:30px
}
.product-box .product-price:last-of-type{
    padding:30px 30px 0 30px;
    border-top:1px solid #e6e9ee;
    border-bottom:0
}
.product-box .product-price:last-of-type .term{
    bottom:4px
}
.product-slider,
.product-configurator{
    padding:58px;
    color:#4a5669;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:12px
}
.product-slider .slider,
.product-configurator .slider{
    position:relative;
    height:20px;
    cursor:pointer;
    background-color:#fff;
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 0 2px #e6e9ee,inset 0 4px 8px rgba(74,86,105,0.1);
    box-shadow:inset 0 0 0 2px #e6e9ee,inset 0 4px 8px rgba(74,86,105,0.1)
}

.product-slider 
.slider 
.range,
.product-configurator .slider .range{
    position:absolute;
    top:0;
    left:0;
    height:20px;
    min-width:16px;
    background-color:#0098ef;
    border-radius:10px
}
.product-slider .slider .range .handle,
.product-configurator .slider .range .handle{
    position:absolute;
    top:-6px;
    right:-8px;
    width:24px;
    height:24px;
    cursor:move;
    background-color:#fff;
    border-width:4px;
    border-style:solid;
    border: color #0098ef;
    ;
    border-radius:16px
}
.product-slider .slider .segment,
.product-configurator .slider .segment{
    float:left;
    height:20px;
    margin-left:-2px;
    border-right:2px solid #e6e9ee
}
.product-slider .slider .segment:last-of-type,
.product-configurator .slider .segment:last-of-type{
    border-right:0
}
.product-slider.hide-segments .slider .segment,
.product-configurator.hide-segments .slider .segment{
    display:none
}
.product-slider>ul{
    display:none;
    margin:15px 0 0 0!important;
    list-style-type:none
}
.product-slider>ul.active{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.product-slider>ul li{
    padding:15px 0;
    margin-top:15px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.product-slider>ul li .value{
    display:block;
    font-size:24px;
    line-height:32px;
    font-family:Montserrat,sans-serif
}
.product-slider>ul li .label{
    display:block;
    margin-top:4px;
    font-size:14px;
    line-height:14px;
    color:#a0abbc
}
.product-slider>ul li:last-of-type{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    padding:15px;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end;
    border:2px solid #e6e9ee;
    border-radius:10px
}
.product-slider>ul li:last-of-type .price{
    margin-right:30px;
    text-align:right
}
.product-slider>ul li:last-of-type .price .value{
    color:#0098ef;
    line-height:30px
}
.product-slider>ul li:first-child:nth-last-child(2),
.product-slider>ul li:first-child:nth-last-child(2) ~ li{
    width:50%
}
.product-slider>ul li:first-child:nth-last-child(3),
.product-slider>ul li:first-child:nth-last-child(3) ~ li{
    width:33.33%
}
.product-slider>ul li:first-child:nth-last-child(4),
.product-slider>ul li:first-child:nth-last-child(4) ~ li{
    width:22.22%
}
.product-slider>ul li:first-child:nth-last-child(5),
.product-slider>ul li:first-child:nth-last-child(5) ~ li{
    width:18%
}
.product-slider>ul li:first-child:nth-last-child(6),
.product-slider>ul li:first-child:nth-last-child(6) ~ li{
    width:20%;
    text-align:center
}
.product-slider>ul li:first-child:nth-last-child(4):last-of-type,
.product-slider>ul li:first-child:nth-last-child(4) ~ li:last-of-type{
    width:33.33%
}
.product-slider>ul li:first-child:nth-last-child(5):last-of-type,
.product-slider>ul li:first-child:nth-last-child(5) ~ li:last-of-type{
    width:28%
}
.product-slider:not(.product-slider-medium)>ul li:first-child:nth-last-child(6):last-of-type,
.product-slider:not(.product-slider-medium)>ul li:first-child:nth-last-child(6) ~ li:last-of-type{
    width:100%;
    margin-top:30px;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.product-slider:not(.product-slider-small)>ul li:first-child:nth-last-child(6):last-of-type .price,
.product-slider:not(.product-slider-small)>ul li:first-child:nth-last-child(6) ~ li:last-of-type .price{
    text-align:left
}
.product-slider.product-slider-medium>ul li{
    width:50%!important;
    text-align:center
}
.product-slider.product-slider-medium>ul li:last-of-type{
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.product-slider.product-slider-medium>ul li:last-of-type .price{
    text-align:left
}
.product-slider.product-slider-medium>ul li:first-child:nth-last-child(3):last-of-type,
.product-slider.product-slider-medium>ul li:first-child:nth-last-child(3) ~ li:last-of-type,
.product-slider.product-slider-medium>ul li:first-child:nth-last-child(5):last-of-type,
.product-slider.product-slider-medium>ul li:first-child:nth-last-child(5) ~ li:last-of-type{
    width:100%!important;
    margin-top:30px
}
.product-slider.product-slider-small{
    padding:28px
}
.product-slider.product-slider-small>ul li{
    width:100%!important;
    text-align:center;
    margin:0
}
.product-slider.product-slider-small>ul li:last-of-type{
    margin-top:15px!important;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
.product-slider.product-slider-small>ul li:last-of-type .price,
.product-slider.product-slider-small>ul li:last-of-type .button{
    -ms-flex-preferred-size:100%;
    flex-basis:100%
}
.product-slider.product-slider-small>ul li:last-of-type .price{
    text-align:center;
    margin:0
}
.product-slider.product-slider-small>ul li:last-of-type .price:not(:only-child){
    margin:0 0 15px 0
}
.product-configurator .configurator-row:not(:first-child),
.product-configurator .configurator-order:not(:first-child){
    margin-top:60px
}
.product-configurator .configurator-row .value,
.product-configurator .configurator-order .value{
    display:block;
    font-size:24px;
    line-height:32px;
    font-family:Montserrat,sans-serif
}
.product-configurator .configurator-row .label,
.product-configurator .configurator-order .label{
    display:block;
    line-height:24px;
    font-size:14px;
    color:#a0abbc
}
.product-configurator .configurator-group{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-align:end;
    -ms-flex-align:end;
    align-items:flex-end;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    padding-bottom:10px
}
.product-configurator .configurator-group .value:not(:only-child){
    margin-right:10px
}
.product-configurator .configurator-order{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    padding:15px;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    border:2px solid #e6e9ee;
    border-radius:10px
}
.product-configurator .configurator-order .configurator-total .value{
    line-height:30px;
    color:#0098ef
}
.product-configurator .configurator-order .configurator-total .label{
    margin-top:4px;
    line-height:14px
}
.product-configurator .configurator-order .configurator-total:only-child{
    width:100%;
    text-align:center
}
.product-configurator.product-configurator-small{
    padding:28px
}
.product-configurator.product-configurator-small .configurator-row:not(:first-child),
.product-configurator.product-configurator-small .configurator-order:not(:first-child){
    margin-top:30px
}

.product-configurator.product-configurator-small .configurator-group,
.product-configurator.product-configurator-small .configurator-order{
    display:block;
    text-align:center
}
.product-configurator.product-configurator-small .configurator-group .value:not(:only-child){
    margin-right:0
}
.product-configurator.product-configurator-small .configurator-order button{
    width:100%
}
.product-configurator.product-configurator-small .configurator-order .configurator-total:not(:only-child){
    margin-right:0;
    margin-bottom:15px;
    text-align:center
}
.network-map{
    position:relative
}
.network-map ul{
    margin:0!important;
    list-style-type:none
}
.network-map ul li{
    position:absolute;
    z-index:1;
    width:12px;
    height:12px;
    margin:-6px;
    background-color:#0098ef;
    border-radius:6px
}
.network-map ul li a{
    display:block;
    width:12px;
    height:12px
}
.network-map ul li span.label-top-left,
.network-map ul li span.label-top-right,
.network-map ul li span.label-bottom-left,
.network-map ul li span.label-bottom-right{
    position:absolute;
    color:#fff;
    padding:4px 8px;
    margin-top:-8px;
    line-height:20px;
    font-size:14px;
    white-space:nowrap;
    font-family:Montserrat,sans-serif;
    background-color:#0098ef;
    border-radius:4px;
    -webkit-transition:color .2s;
    transition:color .2s
}
.network-map ul li a:hover span.label-top-left,
.network-map ul li a:hover span.label-top-right,
.network-map ul li a:hover span.label-bottom-left,
.network-map ul li a:hover span.label-bottom-right{
    color:rgba(255,255,255,0.6);
    text-decoration:none
}
.network-map ul li span.label-top-left{
    top:-32px;
    right:24px
}
.network-map ul li span.label-top-right{
    top:-32px;
    left:24px
}
.network-map ul li span.label-bottom-left{
    bottom:-40px;
    right:24px
}
.network-map ul li span.label-bottom-right{
    bottom:-40px;
    left:24px
}
.network-map ul li span.label-top-left::before,
.network-map ul li span.label-top-right::before,
.network-map ul li span.label-bottom-left::before,
.network-map ul li span.label-bottom-right::before{
    content:"";
    position:absolute;
    width:24px;
    height:1px;
    background-color:#0098ef
}
.network-map ul li span.label-top-left::before,
.network-map ul li span.label-bottom-right::before{
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
}
.network-map ul li span.label-top-right::before,
.network-map ul li span.label-bottom-left::before{
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg)
}
.network-map ul li span.label-top-left::before{
    top:34px;
    right:-18px
}
.network-map ul li span.label-top-right::before{
    top:34px;
    left:-18px
}
.network-map ul li span.label-bottom-left::before{
    top:-6px;
    right:-18px
}
.network-map ul li span.label-bottom-right::before{
    top:-6px;
    left:-18px
}
.network-map img{
    display:block;
    width:100%;
    opacity:.1
}
.testimonial .testimonial-content{
    color:#4a5669;
    padding:30px;
    background-color:#f0f2f5;
    border-radius:12px
}
.testimonial .testimonial-author{
    position:relative;
    overflow:hidden;
    padding:30px 30px 0 30px;
    margin-top:0!important
}
.testimonial .testimonial-author small{
    color:#a0abbc
}
.testimonial .testimonial-author::before{
    content:"";
    position:absolute;
    left:30px;
    top:-10px;
    width:20px;
    height:20px;
    background-color:#f0f2f5;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
}
.blog-article:not(:first-child){
    padding-top:50px;
    margin-top:60px;
    border-top:2px solid #e6e9ee
}
.blog-article.blog-article-center,
.blog-comment-group.blog-comment-group-center,
.blog-comment-form.blog-comment-form-center{
    max-width:708px;
    margin-left:auto;
    margin-right:auto
}
.blog-article.blog-article-center .blog-article-header{
    text-align:center
}
.blog-article .blog-article-header ul{
    margin:4px -10px 0 -10px!important;
    list-style-type:none
}
.blog-article .blog-article-header ul li{
    display:inline-block;
    margin:0 10px;
    color:#a0abbc
}
.blog-article .blog-article-header ul li img{
    width:24px;
    height:24px;
    margin-right:.5em;
    vertical-align:middle;
    border-radius:12px
}
.blog-article .blog-article-content{
    margin-top:50px
}
.blog-comment-group>h3+p,
.blog-comment-form>h3+p{
    margin-top:5px!important;
    padding-bottom:10px
}
.blog-comment-group>h3 a,
.blog-comment-form>h3 a{
    display:block;
    font-size:16px;
    font-weight:normal;
    font-family:"Open Sans",sans-serif
}
.blog-comment-group.blog-comment-group-center>h3 a,
.blog-comment-form.blog-comment-form-center>h3 a{
    float:right;
    margin-top:2px
}
.blog-comment-group .blog-comment-content{
    color:#4a5669;
    padding:30px;
    background-color:#f0f2f5;
    border-radius:12px
}
.blog-comment-group .blog-comment-author{
    position:relative;
    padding:10px 20px 0 65px;
    margin:0!important;
    list-style-type:none
}
.blog-comment-group .blog-comment-author::before{
    content:"";
    position:absolute;
    left:30px;
    top:0;
    width:0;
    height:0;
    border-top:20px solid #f0f2f5;
    border-left:20px solid transparent
}
.blog-comment-group .blog-comment-author li{
    display:inline-block;
    color:#a0abbc
}
.blog-comment-group .blog-comment-author li:not(:last-child){
    margin-right:15px
}
.blog-comment-group .blog-comment-author li img{
    width:24px;
    height:24px;
    margin-right:.5em;
    vertical-align:middle;
    border-radius:12px
}
.blog-comment-group .blog-comment-item:not(:first-child){
    margin-top:30px
}
.blog-comment-group .blog-comment-item .blog-comment-item{
    margin-left:30px
}
.pagination{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    margin:0!important;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    list-style-type:none
}
.pagination:not(:first-child){
    margin-top:60px!important
}
.pagination>li{
    margin:0 6px
}
.pagination>li.hidden{
    visibility:hidden
}
.pagination>li:first-of-type{
    margin-left:0
}
.pagination>li:last-of-type{
    margin-right:0
}
.pagination>li.directional-left:first-of-type{
    margin-right:auto
}
.pagination>li.directional-right:last-of-type{
    margin-left:auto
}
.pagination>li a{
    display:block;
    min-width:16px;
    height:36px;
    line-height:36px;
    padding:0 10px;
    text-align:center;
    text-decoration:none;
    background-color:#fff;
    background-clip:padding-box;
    border:2px solid #e6e9ee;
    border-radius:6px
}
.pagination>li.active a{
    border-color:#0098ef
}
.pagination>li.directional a{
    height:40px;
    line-height:40px;
    padding:0 12px;
    background-color:transparent!important;
    border:0
}

.pagination>li:not(.active) a:hover{
    background-color:#f0f2f5
}
.tab-group{
    position:relative
}

.tab-group.tab-group-switch-style{
    text-align:center
}
.tab-group>a{
    position:absolute;
    top:-60px;
    left:0;
    width:0;
    height:0;
    visibility:hidden
}

.tab-group>ul{
    margin:0!important;
    list-style-type:none
}
.tab-group.tab-group-switch-style>ul{
    padding:6px;
    display:inline-block;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:30px
}
.tab-group>ul li,
.tab-group .tab-item .tab-item-title{
    padding:10px 28px;
    cursor:pointer;
    color:#0098ef;
    font-size:14px;
    line-height:24px;
    text-transform:uppercase;
    font-family:Montserrat,sans-serif;
    background-color:#fff;
    background-clip:padding-box;
    border:2px solid #e6e9ee;
    border-bottom-width:0;
    -webkit-transition:color .2s,background-color .2s;
    transition:color .2s,background-color .2s;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.tab-group.tab-group-title-lowercase>ul li,
.tab-group.tab-group-title-lowercase .tab-item .tab-item-title{
    text-transform:none
}
.tab-group>ul li{
    position:relative;
    display:inline-block;
    border-top-left-radius:6px;
    border-top-right-radius:6px
}
.tab-group.tab-group-switch-style>ul li{
    padding:8px 20px;
    border:0;
    border-radius:22px
}
.tab-group .tab-item:first-of-type .tab-item-title{
    border-top-left-radius:12px;
    border-top-right-radius:12px
}
.tab-group:not(.tab-group-switch-style)>ul li.active,
.tab-group .tab-item.active .tab-item-title{
    color:#4a5669;
    -webkit-transition:color .2s;
    transition:color .2s
}
.tab-group.tab-group-switch-style>ul > li.active > a:focus-visible{
    color:#fff;
    background-color:#0098ef
}
.tab-group:not(.tab-group-switch-style)>ul li.active::after{
    content:"";
    position:absolute;
    left:0;
    right:0;
    bottom:-2px;
    height:2px;
    background-color:#fff
}
.tab-group:not(.tab-group-switch-style)>ul li:not(:first-of-type){
    margin-left:10px
}
.tab-group.tab-group-switch-style>ul li:not(:first-of-type){
    margin-left:6px
}
.tab-group>ul li:not(.active):hover,
.tab-group .tab-item:not(.active) .tab-item-title:hover{
    background-color:#f0f2f5
}
.tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner{
    padding:58px;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-top-right-radius:12px;
    border-bottom-left-radius:12px;
    border-bottom-right-radius:12px
}
.tab-group.tab-group-collapse-style .tab-item .tab-item-inner{
    padding:28px;
    border-bottom-width:0;
    border-radius:0
}
.tab-group.tab-group-switch-style .tab-item .tab-item-inner{
    margin-top:60px;
    text-align:left
}
.tab-group .tab-item:not(.active):last-of-type .tab-item-title,
.tab-group.tab-group-collapse-style .tab-item.active:last-of-type .tab-item-inner{
    border-bottom-width:2px;
    border-bottom-left-radius:12px;
    border-bottom-right-radius:12px
}
.tab-group.tab-group-collapse-style>ul,
.tab-group:not(.tab-group-collapse-style) .tab-item .tab-item-title,
.tab-group .tab-item:not(.active) .tab-item-inner{
    display:none
}
.gallery::after{
    overflow:hidden;
    position:absolute;
    width:0;
    height:0;
    content:"";
    background-image:url(./logo.svg)
}
.gallery ul li,
.gallery ul li a,
.gallery ul li img{
    display:block;
    width:100%
}
.gallery:not(.gallery-slider) ul{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    margin:-15px!important;
    list-style-type:none
}
.gallery:not(.gallery-slider) ul li{
    width:50%;
    padding:15px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.gallery.gallery-3-columns:not(.gallery-slider) ul li{
    width:33.33%
}
.gallery.gallery-4-columns:not(.gallery-slider) ul li{
    width:25%
}
.gallery.gallery-5-columns:not(.gallery-slider) ul li{
    width:20%
}
.gallery.gallery-6-columns:not(.gallery-slider) ul li{
    width:16.66%
}
.gallery:not(.gallery-slider) ul li:first-of-type:last-of-type{
    width:100%
}
.gallery:not(.gallery-slider) ul li img{
    border-radius:6px
}
.gallery.gallery-compact:not(.gallery-slider){
    overflow:hidden;
    border-radius:6px
}
.gallery.gallery-compact:not(.gallery-slider) ul,
.gallery.gallery-compact:not(.gallery-slider) ul li{
    margin:0;
    padding:0
}
.gallery.gallery-compact:not(.gallery-slider) ul li img{
    border-radius:0
}
.gallery.gallery-slider{
    overflow:hidden;
    position:relative;
    border-radius:6px;
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
}
.gallery.gallery-slider.gallery-animate{
    -webkit-transition:height .6s;
    transition:height .6s
}
.gallery.gallery-slider .gallery-previous,
.gallery.gallery-slider .gallery-next{
    position:absolute;
    z-index:1;
    top:20px;
    width:34px;
    height:34px;
    line-height:34px;
    text-align:center;
    font-size:24px;
    cursor:pointer;
    color:#fff;
    background-color:rgba(18,22,28,0.4);
    -webkit-transition:background-color .2s;
    transition:background-color .2s
}
.gallery.gallery-slider .gallery-previous{
    right:56px;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
}
.gallery.gallery-slider .gallery-next{
    right:20px;
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
}
.gallery.gallery-slider .gallery-previous:hover,
.gallery.gallery-slider .gallery-next:hover{
    background-color:#0098ef
}
.gallery.gallery-slider ul{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    margin:0!important;
    list-style-type:none;
    -webkit-transition:-webkit-transform .6s;
    transition:-webkit-transform .6s;
    transition:transform .6s;
    transition:transform .6s,-webkit-transform .6s
}
#gallery-overlay{
    overflow:hidden;
    position:fixed;
    z-index:1000;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(18,22,28,0.9)
}
#gallery-overlay ul,#gallery-overlay ul li{
    position:absolute;
    list-style-type:none;
    height:100%;
    -webkit-transition:-webkit-transform .6s;
    transition:-webkit-transform .6s;
    transition:transform .6s;
    transition:transform .6s,-webkit-transform .6s
}
#gallery-overlay ul li{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    padding:60px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
#gallery-overlay ul li .loading{
    position:absolute;
    top:50%;
    left:50%;
    width:32px;
    height:32px;
    margin:-16px 0 0 -16px;
    background:transparent url(./logo.svg) center center no-repeat;
    -webkit-animation:gallery-overlay-loading .6s linear infinite;
    animation:gallery-overlay-loading .6s linear infinite;
    -webkit-transition:opacity .2s;
    transition:opacity .2s
}

#gallery-overlay ul li .loading.hide{
    opacity:0
}

#gallery-overlay ul li img{
    display:block;
    max-width:100%;
    max-height:100%;
    opacity:0
}
#gallery-overlay ul li img.ready{
    opacity:1;
    -webkit-animation:gallery-overlay-ready .2s;
    animation:gallery-overlay-ready .2s
}
#gallery-overlay-close,
#gallery-overlay-previous,
#gallery-overlay-next{
    position:absolute;
    z-index:1001;
    width:60px;
    height:60px;
    line-height:60px;
    margin:0;
    cursor:pointer;
    text-align:center;
    color:rgba(255,255,255,0.3);
    -webkit-transition:color .2s;
    transition:color .2s
}
#gallery-overlay-close{
    top:0;
    right:0;
    font-size:30px
}
#gallery-overlay-previous,#gallery-overlay-next{
    top:50%;
    margin-top:-30px;
    font-size:40px
}
#gallery-overlay-previous{
    left:0
}
#gallery-overlay-next{
    right:0
}
#gallery-overlay-close:hover,
#gallery-overlay-previous:hover,
#gallery-overlay-next:hover{
    color:#fff
}
#gallery-overlay-title{
    overflow:hidden;
    position:absolute;
    z-index:1001;
    bottom:0;
    left:30px;
    right:30px;
    height:60px;
    line-height:60px;
    text-align:center;
    white-space:nowrap;
    text-overflow:ellipsis;
    color:rgba(255,255,255,0.3);
    -webkit-transition:opacity .3s;
    transition:opacity .3s
}
#gallery-overlay-title.hide{
    opacity:0
}
@-webkit-keyframes gallery-overlay-loading{
    0%{
    -webkit-transform:rotate(0);
    transform:rotate(0)
}
100%{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
}

}
@keyframes gallery-overlay-loading{
    0%{
    -webkit-transform:rotate(0);
    transform:rotate(0)
}
100%{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
}

}
@-webkit-keyframes gallery-overlay-ready{
    0%{
    opacity:0;
    -webkit-transform:scale(0.9);
    transform:scale(0.9)
}
100%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}

}
@keyframes gallery-overlay-ready{
    0%{
    opacity:0;
    -webkit-transform:scale(0.9);
    transform:scale(0.9)
}
100%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}

}
#video-overlay,#iframe-overlay{
    overflow:hidden;
    position:fixed;
    z-index:1000;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(18,22,28,0.9)
}
#video-overlay video,#video-overlay iframe,#iframe-overlay iframe{
    position:absolute;
    top:50%;
    left:50%;
    width:1280px;
    height:720px;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%)
}
#video-overlay-close,#iframe-overlay-close{
    position:absolute;
    z-index:1001;
    top:0;
    right:0;
    width:60px;
    height:60px;
    line-height:60px;
    font-size:30px;
    margin:0;
    cursor:pointer;
    text-align:center;
    color:rgba(255,255,255,0.3);
    -webkit-transition:color .2s;
    transition:color .2s
}
#video-overlay-close:hover,
#iframe-overlay-close:hover{
    color:#fff
}
#video-overlay-icon{
    position:absolute;
    top:50%;
    left:50%;
    width:60px;
    height:60px;
    line-height:60px;
    margin:-30px 0 0 -30px;
    font-size:32px;
    color:#0098ef;
    text-align:center;
    background-color:#fff;
    border-radius:30px;
    -webkit-box-shadow:0 15px 30px rgba(74,86,105,0.1);
    box-shadow:0 15px 30px rgba(74,86,105,0.1);
    -webkit-transition:color .2s,background-color .2s;
    transition:color .2s,background-color .2s
}
.video-overlay.video-overlay-has-icon{
    position:relative;
    display:inline-block;
    margin-bottom:-1px
}
.video-overlay.video-overlay-has-icon>img{
    display:block
}
.video-overlay.video-overlay-has-icon:hover 
#video-overlay-icon{
    color:#fff;
    background-color:#0098ef
}
.iframe-overlay{
    cursor:pointer
}
#nav-overlay{
    position:absolute;
    z-index:1001;
    top:0;
    left:0;
    width:100%;
    padding:30px 0
}
#nav-overlay .logo{
    float:none!important
}
#nav-overlay:not(.nav-overlay-light):not(.nav-overlay-gray):not(.nav-overlay-dark) .logo{
    color:#fff
}
#nav-overlay nav ul{
    list-style-type:none
}
#nav-overlay nav .nav-primary{
    margin-top:30px;
    padding:20px 30px;
    border:2px solid rgba(255,255,255,0.2);
    border-radius:12px
}
#nav-overlay.nav-overlay-light nav .nav-primary,
#nav-overlay.nav-overlay-gray nav .nav-primary{
    background-color:#fff;
    border-color:#e6e9ee
}
#nav-overlay.nav-overlay-dark nav .nav-primary{
    border-color:#3a4659
}
#nav-overlay nav .nav-primary>li{
    padding:10px 0;
    line-height:20px
}
#nav-overlay nav .nav-primary>li a:not(.button){
    color:#fff
}
#nav-overlay.nav-overlay-light nav .nav-primary>li a:not(.button),#nav-overlay.nav-overlay-gray nav .nav-primary>li a:not(.button),#nav-overlay.nav-overlay-dark nav .nav-primary>li a:not(.button){
    color:#0098ef
}
#nav-overlay nav .nav-primary>li>a:not(.button){
    font-size:14px;
    font-weight:bold;
    font-family:Montserrat,sans-serif;
    text-transform:uppercase
}
#nav-overlay:not(.nav-overlay-light):not(.nav-overlay-gray):not(.nav-overlay-dark) nav .nav-primary>li>a.button.button-primary{
    padding:16px 20px;
    border:2px solid rgba(255,255,255,0.2)
}
#nav-overlay:not(.nav-overlay-light):not(.nav-overlay-gray) nav .nav-primary>li>a.button:not(.button-primary){
    padding:18px 22px;
    border:0
}
#nav-overlay nav .nav-primary>li a:not(.button):hover{
    color:rgba(255,255,255,0.6);
    text-decoration:none
}
#nav-overlay.nav-overlay-light nav .nav-primary>li a:not(.button):hover,#nav-overlay.nav-overlay-gray nav .nav-primary>li a:not(.button):hover{
    color:#a0abbc
}
#nav-overlay.nav-overlay-dark nav .nav-primary>li a:not(.button):hover{
    color:#667791
}
#nav-overlay nav .nav-primary>li ul{
    padding:20px 0 10px 0
}
#nav-overlay nav .nav-primary>li ul li{
    padding:5px 0;
    font-size:14px;
    line-height:20px
}
#nav-overlay nav .nav-primary>li ul li ul{
    padding:20px 0 15px 30px
}
#nav-overlay nav .nav-primary>li:last-of-type ul,#nav-overlay nav .nav-primary>li ul li:last-of-type,#nav-overlay nav .nav-primary>li ul li:last-of-type ul{
    padding-bottom:0
}
#nav-overlay nav .nav-secondary{
    padding:25px 30px
}
#nav-overlay nav .nav-secondary li{
    padding:5px 0;
    font-size:14px;
    line-height:20px
}
#nav-overlay nav .nav-secondary li a{
    color:rgba(255,255,255,0.6)
}
#nav-overlay.nav-overlay-light nav .nav-secondary li a,#nav-overlay.nav-overlay-gray nav .nav-secondary li a{
    color:#a0abbc
}
#nav-overlay.nav-overlay-dark nav .nav-secondary li a{
    color:#667791
}
#nav-overlay nav .nav-secondary li a:hover{
    color:#fff;
    text-decoration:none
}
#nav-overlay.nav-overlay-light nav .nav-secondary li a:hover,#nav-overlay.nav-overlay-gray nav .nav-secondary li a:hover{
    color:#4a5669
}
#nav-overlay nav .nav-secondary li a i{
    width:20px;
    text-align:center
}
#nav-overlay-open,#nav-overlay-close{
    position:absolute;
    top:0;
    right:0;
    width:32px;
    height:32px;
    margin-top:12px;
    font-size:24px;
    line-height:32px;
    text-align:center;
    cursor:pointer;
    color:#fff;
    border:2px solid rgba(255,255,255,0.2);
    border-radius:6px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}

#nav-overlay-open.nav-overlay-light,
#nav-overlay-close.nav-overlay-light,
#nav-overlay-open.nav-overlay-gray,
#nav-overlay-close.nav-overlay-gray{
    color:#0098ef;
    background-color:#fff;
    border-color:#e6e9ee
}

#nav-overlay-open.nav-overlay-dark,#nav-overlay-close.nav-overlay-dark{
    color:#0098ef;
    border-color:#3a4659
}

#nav-overlay-background{
    position:fixed;
    z-index:1000;
    top:0;
    left:0;
    width:100%;
    height:100%;
    padding-bottom:100%;
    background-color:#0098ef
}

#nav-overlay-background.nav-overlay-light{
    background-color:#fff!important
}

#nav-overlay-background.nav-overlay-gray{
    background-color:#f0f2f5!important
}

#nav-overlay-background.nav-overlay-dark{
    background-color:#2c3645!important
}

#nav-overlay:not(.nav-overlay-visible),#nav-overlay-open:not(.nav-overlay-visible),
#nav-overlay-close:not(.nav-overlay-visible),#nav-overlay-background:not(.nav-overlay-visible){
    display:none
}

#modal-overlay{
    overflow:auto;
    position:fixed;
    z-index:1000;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(18,22,28,0.9)
}

#modal-overlay>.modal-container{
    position:relative;
    margin:60px auto;
    width:720px;
    -webkit-animation:modal-container-slide-in .4s;
    animation:modal-container-slide-in .4s
}
@-webkit-keyframes modal-container-slide-in{
    0%{
    opacity:0;
    -webkit-transform:translateY(-60px);
    transform:translateY(-60px)
}
100%{
    opacity:1;
    -webkit-transform:translateY(0);
    transform:translateY(0)
}

}
@keyframes modal-container-slide-in{
    0%{
    opacity:0;
    -webkit-transform:translateY(-60px);
    transform:translateY(-60px)
}
100%{
    opacity:1;
    -webkit-transform:translateY(0);
    transform:translateY(0)
}

}
#modal-overlay>.modal-container.modal-container-small{
    width:480px
}
#modal-overlay>.modal-container.modal-container-large{
    width:1280px
}
#modal-overlay>.modal-container>.modal-header{
    min-height:34px;
    padding:20px 60px 20px 30px;
    background-color:#fff;
    border-bottom:2px solid #e6e9ee;
    border-top-left-radius:12px;
    border-top-right-radius:12px
}
#modal-overlay>.modal-container>.modal-header>.modal-dismiss{
    position:absolute;
    top:22px;
    right:22px;
    width:30px;
    height:30px;
    line-height:30px;
    font-size:24px;
    color:#a0abbc;
    cursor:pointer;
    text-align:center;
    -webkit-transition:color .2s;
    transition:color .2s;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
#modal-overlay>.modal-container>.modal-header>.modal-dismiss:hover{
    color:#4a5669
}
#modal-overlay>.modal-container>.modal-content{
    padding:30px;
    background-color:#fff;
    border-bottom-left-radius:12px;
    border-bottom-right-radius:12px
}
#modal-overlay>.modal-container>.modal-content ul,#modal-overlay>.modal-container>.modal-content ol{
    line-height:28px
}
#modal-overlay>.modal-container>.modal-content ul:not(.list-style-nav):not(.list-style-none):not(.list-style-icon),#modal-overlay>.modal-container>.modal-content ol{
    margin-left:20px
}
#modal-overlay>.modal-container>.modal-content ul li ul,#modal-overlay>.modal-container>.modal-content ul li ol,#modal-overlay>.modal-container>.modal-content ol li ul,#modal-overlay>.modal-container>.modal-content ol li ol{
    margin:15px 20px
}
#modal-overlay>.modal-container>.modal-content ul li:last-child ul,#modal-overlay>.modal-container>.modal-content ul li:last-child ol,#modal-overlay>.modal-container>.modal-content ol li:last-child ul,#modal-overlay>.modal-container>.modal-content ol li:last-child ol{
    margin-bottom:0
}
#modal-overlay>.modal-container>.modal-content h1:not(:first-child),#modal-overlay>.modal-container>.modal-content h2:not(:first-child),#modal-overlay>.modal-container>.modal-content h3:not(:first-child),#modal-overlay>.modal-container>.modal-content h4:not(:first-child),#modal-overlay>.modal-container>.modal-content h5:not(:first-child){
    margin-top:40px
}
#modal-overlay>.modal-container>.modal-content p:not(:first-child),#modal-overlay>.modal-container>.modal-content ul:not(:first-child),#modal-overlay>.modal-container>.modal-content ol:not(:first-child),#modal-overlay>.modal-container>.modal-content dl:not(:first-child),#modal-overlay>.modal-container>.modal-content hr:not(:first-child),#modal-overlay>.modal-container>.modal-content pre:not(:first-child),#modal-overlay>.modal-container>.modal-content form:not(:first-child),#modal-overlay>.modal-container>.modal-content table:not(:first-child),#modal-overlay>.modal-container>.modal-content iframe:not(:first-child),#modal-overlay>.modal-container>.modal-content address:not(:first-child),#modal-overlay>.modal-container>.modal-content blockquote:not(:first-child),#modal-overlay>.modal-container>.modal-content .pre-code:not(:first-child),#modal-overlay>.modal-container>.modal-content .feature-box:not(:first-child),#modal-overlay>.modal-container>.modal-content .product-box:not(:first-child),#modal-overlay>.modal-container>.modal-content .product-slider:not(:first-child),#modal-overlay>.modal-container>.modal-content .product-configurator:not(:first-child),#modal-overlay>.modal-container>.modal-content .network-map:not(:first-child),#modal-overlay>.modal-container>.modal-content .testimonial:not(:first-child),#modal-overlay>.modal-container>.modal-content .tab-group:not(:first-child),#modal-overlay>.modal-container>.modal-content .gallery:not(:first-child){
    margin-top:20px
}
#modal-overlay>.modal-container>.modal-content h1+.column_row,#modal-overlay>.modal-container>.modal-content h2+.column_row,#modal-overlay>.modal-container>.modal-content h3+.column_row,#modal-overlay>.modal-container>.modal-content h4+.column_row,#modal-overlay>.modal-container>.modal-content h5+.column_row,#modal-overlay>.modal-container>.modal-content p+.column_row,#modal-overlay>.modal-container>.modal-content ul+.column_row,#modal-overlay>.modal-container>.modal-content ol+.column_row,#modal-overlay>.modal-container>.modal-content dl+.column_row,#modal-overlay>.modal-container>.modal-content hr+.column_row{
    margin-top:-10px
}

@media(max-width:1340px){
    #modal-overlay>.modal-container.modal-container-large{
    margin:0;
    width:100%
}
#modal-overlay>.modal-container.modal-container-large>.modal-header,#modal-overlay>.modal-container.modal-container-large>.modal-content{
    border-radius:0
}

}

@media(max-width:780px){
    #modal-overlay>.modal-container:not(.modal-container-small){
    margin:0;
    width:100%
}

#modal-overlay>.modal-container:not(.modal-container-small)>.modal-header,
#modal-overlay>.modal-container:not(.modal-container-small)>.modal-content{
    border-radius:0
}

}


@media(max-width:540px){
    #modal-overlay>.modal-container.modal-container-small{
    margin:0;
    width:100%
}

#modal-overlay>.modal-container.modal-container-small>.modal-header,
#modal-overlay>.modal-container.modal-container-small>.modal-content{
    border-radius:0
}

}
.modal{
    display:none!important
}
.group{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    margin-left:-10px;
    margin-right:-10px;
    margin-bottom:-20px;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.group.align-center{
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
.group.align-left{
    -webkit-box-pack:start;
    -ms-flex-pack:start;
    justify-content:flex-start
}
.group.align-right{
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end
}
.group.space-between{
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.group>*{
    margin:0 10px 20px 10px!important
}
.group>.group-stretch{
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1
}
.column_row,
#content>.content_row .contentHeader+.column_row{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    margin:-30px
}
.column_row.column_row-flex-width{
    -webkit-box-pack:justify!important;
    -ms-flex-pack:justify!important;
    justify-content:space-between!important
}
.column_row:not(:first-child){
    margin-top:30px
}
.column_row.align-center,
.column_row.align-center-top,
.column_row.align-center-center,
.column_row.align-center-bottom{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
.column_row.align-left-top,
.column_row.align-left-center,
.column_row.align-left-bottom{
    -webkit-box-align:start;
    -ms-flex-align:start;
    align-items:flex-start;
    -webkit-box-pack:start;
    -ms-flex-pack:start;
    justify-content:flex-start
}
.column_row.align-right-top,
.column_row.align-right-center,
.column_row.align-right-bottom{
    -webkit-box-align:start;
    -ms-flex-align:start;
    align-items:flex-start;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end
}
.column_row.align-center-top{
    -webkit-box-align:start;
    -ms-flex-align:start;
    align-items:flex-start
}
.column_row.align-left-center,
.column_row.align-right-center{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.column_row.align-left-bottom,
.column_row.align-center-bottom,
.column_row.align-right-bottom{
    -webkit-box-align:end;
    -ms-flex-align:end;
    align-items:flex-end
}
.column_row>.column-20,
.column_row>.column-25,
.column_row>.column-30,
.column_row>.column-33,
.column_row>.column-40,
.column_row>.column-50,
.column_row>.column-60,
.column_row>.column-66,
.column_row>.column-70,
.column_row>.column-75,
.column_row>.column-80,
.column_row>.column-flex{
    padding:30px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
.column_row>.column-20{
    width:20%
}
.column_row>.column-25{
    width:25%
}
.column_row>.column-30{
    width:30%
}
.column_row>.column-33{
    width:33.33%
}
.column_row>.column-40{
    width:40%
}
.column_row>.column-50{
    width:50%
}
.column_row>.column-60{
    width:60%
}
.column_row>.column-66{
    width:66.66%
}
.column_row>.column-70{
    width:70%
}
.column_row>.column-75{
    width:75%
}
.column_row>.column-80{
    width:80%
}
.container{
    position:relative;
    width:1280px;
    margin:0 auto
}
.container.container-full-width{
    width:auto;
    margin:0 60px;
    -ms-flex-preferred-size:100%;
    flex-basis:100%
}
.container::after{
    content:"";
    display:table;
    clear:both
}
#header{
    position:fixed;
    z-index:100;
    top:0;
    left:0;
    width:100%;
    height:60px;
    padding:30px 0;
    background-color:#0098ef;
    -webkit-transition:-webkit-transform .4s,-webkit-box-shadow .4s;
    transition:-webkit-transform .4s,-webkit-box-shadow .4s;
    transition:transform .4s,box-shadow .4s;
    transition:transform .4s,box-shadow .4s,-webkit-transform .4s,-webkit-box-shadow .4s
}
#header.header-static{
    position:absolute
}
#header.header-light{
    background-color:#fff!important
}
#header.header-gray{
    background-color:#f0f2f5!important
}
#header.header-dark{
    background-color:#2c3645!important
}
#header.header-dynamic.headroom--pinned.headroom--top{
    -webkit-transition:-webkit-box-shadow .4s;
    transition:-webkit-box-shadow .4s;
    transition:box-shadow .4s;
    transition:box-shadow .4s,-webkit-box-shadow .4s
}
#header.header-dynamic.headroom--pinned{
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
}
#header.header-dynamic.headroom--unpinned{
    -webkit-transform:translate3d(0,-100%,0);
    transform:translate3d(0,-100%,0)
}
#header.header-dynamic.headroom--disabled{
    -webkit-transform:translate3d(0,0,0)!important;
    transform:translate3d(0,0,0)!important
}
#header.header-shadow,#header.header-shadow-scroll.active{
    -webkit-box-shadow:0 16px 32px rgba(44,54,69,0.05);
    box-shadow:0 16px 32px rgba(44,54,69,0.05)
}
#header.header-shadow.headroom--unpinned,#header.header-shadow-scroll.active.headroom--unpinned{
    -webkit-box-shadow:none;
    box-shadow:none
}
#header .logo,#nav-overlay .logo{
    float:left;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    max-width:30%;
    height:60px;
    line-height:60px;
    text-decoration:none!important
}
#header .logo img,#nav-overlay .logo img{
    display:block;
    max-width:100%;
    max-height:100%
}
#header .logo .logo-text,#nav-overlay .logo .logo-text{
    font-size:32px;
    white-space:nowrap;
    font-family:Montserrat,sans-serif
}
#header .logo .logo-text small,#nav-overlay .logo .logo-text small{
    font-size:50%;
    line-height:50%;
    color:rgba(255,255,255,0.6)
}
#header.header-gray .logo .logo-text small,#header.header-light .logo .logo-text small,#nav-overlay.nav-overlay-gray .logo .logo-text small,#nav-overlay.nav-overlay-light .logo .logo-text small{
    color:#a0abbc
}
#header.header-dark .logo .logo-text small,#nav-overlay.nav-overlay-dark .logo .logo-text small{
    color:#667791
}
#header:not(.header-nav-overlay) .logo .logo-text.logo-text-baseline{
    padding-top:16px
}
#header .logo.logo-center{
    float:none;
    margin:0 auto;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
#header nav{
    float:right;
    width:70%
}

#header.header-nav-overlay nav{
    display:none
}
#header nav ul{
    list-style-type:none
}

#header nav .nav-primary{
    float:right;
    margin-top:28px
}
#header nav .nav-primary>li{
    position:relative;
    float:left
}

#header nav .nav-primary>li:not(:last-of-type){
    margin-right:40px
}
#header nav .nav-primary>li.button-follows{
    margin-right:10px
}

#header nav .nav-primary>li>a{
    display:block;
    height:32px;
    line-height:32px;
    font-size:14px;
    font-weight:bold;
    font-family:Montserrat,sans-serif;
    text-transform:uppercase
}
#header:not(.header-light):not(.header-gray):not(.header-dark) .logo,#header:not(.header-light):not(.header-gray):not(.header-dark) nav .nav-primary>li>a:not(.button){
    color:#fff
}
#header nav .nav-primary>li>a:hover:not(.button){
    color:#a0abbc;
    text-decoration:none
}
#header.header-dark nav .nav-primary>li>a:hover:not(.button){
    color:#667791
}
#header:not(.header-light):not(.header-gray):not(.header-dark) nav .nav-primary>li>a:hover:not(.button){
    color:rgba(255,255,255,0.6)
}
#header nav .nav-primary>li>a.button{
    padding:0 16px;
    border-radius:4px
}
#header:not(.header-light):not(.header-gray):not(.header-dark) nav .nav-primary>li>a.button.button-primary,#header.header-light nav .nav-primary>li>a.button:not(.button-primary):not(.button-secondary),#header.header-gray nav .nav-primary>li>a.button:not(.button-primary):not(.button-secondary){
    line-height:28px;
    padding:0 14px
}
#header:not(.header-light):not(.header-gray):not(.header-dark) nav .nav-primary>li>a.button.button-primary{
    border:2px solid rgba(255,255,255,0.2)
}
#header:not(.header-light):not(.header-gray) nav .nav-primary>li>a.button:not(.button-primary){
    border:0
}
#header nav .nav-primary>li ul,#header.header-dynamic.headroom--unpinned nav .nav-primary>li ul{
    display:none;
    position:absolute;
    padding:10px;
    margin:10px 0 0 -20px;
    background-color:#fff;
    border-radius:6px;
    -webkit-box-shadow:0 0 0 2px rgba(74,86,105,0.1),0 15px 30px rgba(74,86,105,0.1);
    box-shadow:0 0 0 2px rgba(74,86,105,0.1),0 15px 30px rgba(74,86,105,0.1)
}
#header nav .nav-primary>li>ul.nav-button{
    margin-left:0
}
#header nav .nav-primary>li:hover>ul{
    display:block;
    -webkit-animation:nav-primary-down .2s;
    animation:nav-primary-down .2s
}
#header nav .nav-primary>li>ul::before{
    position:absolute;
    content:"";
    top:-10px;
    left:0;
    right:0;
    height:10px
}
#header nav .nav-primary>li ul li{
    position:relative;
    line-height:26px;
    white-space:nowrap
}
#header nav .nav-primary>li ul li a{
    display:block;
    padding:4px 10px;
    font-size:15px;
    border-radius:4px
}
#header nav .nav-primary>li ul li a:hover{
    color:#a0abbc;
    text-decoration:none;
    background-color:#f0f2f5
}
#header nav .nav-primary>li ul li ul{
    top:-20px;
    left:100%;
    margin-left:20px
}
#header nav .nav-primary>li ul li:hover ul{
    display:block;
    -webkit-animation:nav-primary-right .2s;
    animation:nav-primary-right .2s
}
#header nav .nav-primary>li ul li ul::before{
    position:absolute;
    content:"";
    top:0;
    bottom:0;
    left:-20px;
    width:20px
}
#header nav .nav-primary>li>ul.nav-left{
    right:0;
    margin:10px -20px 0 0
}
#header nav .nav-primary>li>ul.nav-left.nav-button{
    margin-right:0
}
#header nav .nav-primary>li ul.nav-left li a{
    text-align:right
}
#header nav .nav-primary>li ul li ul.nav-left{
    left:auto;
    right:100%;
    margin-right:20px
}
#header nav .nav-primary>li ul li ul.nav-left::before{
    left:auto;
    right:-20px
}
#header nav .nav-primary>li ul li:hover ul.nav-left{
    display:block;
    -webkit-animation:nav-primary-left .2s;
    animation:nav-primary-left .2s
}
@-webkit-keyframes nav-primary-down{
    0%{
    opacity:0;
    -webkit-transform:translateY(-10px);
    transform:translateY(-10px)
}
100%{
    opacity:1;
    -webkit-transform:translateY(0);
    transform:translateY(0)
}

}
@keyframes nav-primary-down{
    0%{
    opacity:0;
    -webkit-transform:translateY(-10px);
    transform:translateY(-10px)
}
100%{
    opacity:1;
    -webkit-transform:translateY(0);
    transform:translateY(0)
}

}
@-webkit-keyframes nav-primary-right{
    0%{
    opacity:0;
    -webkit-transform:translateX(-10px);
    transform:translateX(-10px)
}
100%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}

}
@keyframes nav-primary-right{
    0%{
    opacity:0;
    -webkit-transform:translateX(-10px);
    transform:translateX(-10px)
}
100%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}

}
@-webkit-keyframes nav-primary-left{
    0%{
    opacity:0;
    -webkit-transform:translateX(10px);
    transform:translateX(10px)
}
100%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}

}
@keyframes nav-primary-left{
    0%{
    opacity:0;
    -webkit-transform:translateX(10px);
    transform:translateX(10px)
}
100%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}

}
#header nav .nav-secondary{
    position:absolute;
    top:0;
    right:0;
    -webkit-font-smoothing:subpixel-antialiased
}
#header nav .nav-secondary li{
    float:left
}
#header nav .nav-secondary li:not(:last-of-type){
    margin-right:30px
}
#header nav .nav-secondary li a{
    display:block;
    font-size:13px;
    color:rgba(255,255,255,0.6)
}
#header.header-light nav .nav-secondary li a,#header.header-gray nav .nav-secondary li a{
    color:#a0abbc
}
#header.header-dark nav .nav-secondary li a{
    color:#667791
}
#header nav .nav-secondary li a:hover{
    color:#fff;
    text-decoration:none
}
#header.header-light nav .nav-secondary li a:hover,#header.header-gray nav .nav-secondary li a:hover{
    color:#4a5669
}
#notification{
    display:none;
    overflow:hidden;
    max-height:240px;
    background-color:#00629a;
    -webkit-transition:max-height .6s;
    transition:max-height .6s
}
#notification.notification-hide{
    max-height:0
}
#notification.notification-error{
    background-color:#cd3100
}
#notification.notification-warning{
    background-color:#ffcc34
}
#notification.notification-success{
    background-color:#00b75a
}
#notification .container::after{
    display:block
}
#notification p{
    margin:20px 40px;
    color:#fff;
    text-align:center;
    -webkit-transition:opacity .6s;
    transition:opacity .6s
}
#notification.notification-hide p{
    opacity:0
}
#notification p a{
    color:#fc3;
    white-space:nowrap
}
#notification.notification-warning p,#notification.notification-warning p a{
    color:#4a5669
}
#notification.notification-warning p a,#notification.notification-warning p a .fa,#notification.notification-warning p a .fas,#notification.notification-warning p a .far,#notification.notification-warning p a .fal,#notification.notification-warning p a .fab{
    font-weight:bold
}

#notification.notification-warning 
#notification-dismiss{
    color:#4a5669
}

#notification.notification-warning 
#notification-dismiss:hover{
    color:rgba(74,86,105,0.5)
}

#notification.notification-warning.hide 
#notification-dismiss{
    color:rgba(74,86,105,0)
}

#notification-dismiss{
    position:absolute;
    top:50%;
    right:0;
    width:24px;
    height:24px;
    margin-top:-12px;
    font-size:24px;
    line-height:24px;
    text-align:center;
    cursor:pointer;
    -webkit-transition:color .2s;
    transition:color .2s;
    color:rgba(255,255,255,0.5)
}
#notification-dismiss:hover{
    color:#fff
}
#notification.notification-hide #notification-dismiss{
    color:rgba(255,255,255,0)
}
#notification.notification-dismiss-align #notification-dismiss{
    right:6px
}

#content>.content_row{
    position:relative;
    overflow:hidden;
    padding:60px 0;
    background-color:#fff
}

#content>.content_row.content_row-half-height,
#content>.content_row.content_row-full-height{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}

#content>.content_row.content_row-border-top{
    border-top:2px solid #e6e9ee
}

#content>.content_row.content_row-border-bottom{
    border-bottom:2px solid #e6e9ee
}



#content>.content_row.content_row_clouds::before,
#content>.content_row.content_row_clouds::after{
    content:"";
    position:absolute;
    z-index:2;
    bottom:0;
    width:1046px;
    height:178px;
    background-size:1046px 178px;
    background-repeat:no-repeat
}

#content>.content_row.content_row_clouds::before{
    right:50%;
    padding-right:320px;
    background-position:left bottom;
    background-image:url(./img/layout/clouds-gray-left.png)
}

#content>.content_row.content_row_clouds::after{
    left:50%;
    padding-left:320px;
    background-position:right bottom;
    background-image:url(./img/layout/clouds-gray-right.png)
}

#content>.content_row.content_row-split-left::after,
#content>.content_row.content_row-split-right::after{
    content:"";
    top:0;
    bottom:0;
    height:auto;
    position:absolute;
    background-color:#fff
}

#content>.content_row.content_row-split-left::after{
    left:0;
    right:50%
}

#content>.content_row.content_row-split-right::after{
    left:50%;
    right:0
}

#content>.content_row.content_row-split-left .container,
#content>.content_row.content_row-split-right .container{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}

#content>.content_row.content_row-split-left .container:not(.container-full-width){
    padding-right:700px
}

#content>.content_row.content_row-split-right .container:not(.container-full-width){
    padding-left:700px
}

#content>.content_row.content_row-split-left .container.container-full-width,
#content>.content_row.content_row-split-right .container.container-full-width{
    -ms-flex-preferred-size:50%;
    flex-basis:50%;
    width:50%;
    margin:0;
    padding:0 60px
}

#content>.content_row.content_row-split-right .container.container-full-width{
    margin-left:auto
}

#content>.content_row .container{
    z-index:3
}

#content>.content_row ul,
#content>.content_row ol{
    line-height:28px
}

#content>.content_row ul:not(.list-style-nav):not(.list-style-none):not(.list-style-icon),
#content>.content_row ol{
    margin-left:20px
}

#content>.content_row ul li ul,
#content>.content_row ul li ol,
#content>.content_row ol li ul,
#content>.content_row ol li ol{
    margin:15px 20px
}

#content>.content_row ul li:last-child ul,
#content>.content_row ul li:last-child ol,
#content>.content_row ol li:last-child ul,
#content>.content_row ol li:last-child ol{
    margin-bottom:0
}

#content>.content_row h1:not(:first-child),
#content>.content_row h2:not(:first-child),
#content>.content_row h3:not(:first-child),
#content>.content_row h4:not(:first-child),
#content>.content_row h5:not(:first-child){
    margin-top:40px
}

#content>.content_row p:not(:first-child),
#content>.content_row ul:not(:first-child),
#content>.content_row ol:not(:first-child),
#content>.content_row dl:not(:first-child),
#content>.content_row hr:not(:first-child),
#content>.content_row pre:not(:first-child),
#content>.content_row form:not(:first-child),
#content>.content_row table:not(:first-child),
#content>.content_row iframe:not(:first-child),
#content>.content_row address:not(:first-child),
#content>.content_row blockquote:not(:first-child),
#content>.content_row .pre-code:not(:first-child),
#content>.content_row .feature-box:not(:first-child),
#content>.content_row .product-box:not(:first-child),
#content>.content_row .product-slider:not(:first-child),
#content>.content_row .product-configurator:not(:first-child),
#content>.content_row .network-map:not(:first-child),
#content>.content_row .testimonial:not(:first-child),
#content>.content_row .tab-group:not(:first-child),
#content>.content_row .gallery:not(:first-child){
    margin-top:20px
}

#content>.content_row h1+.column_row,
#content>.content_row h2+.column_row,
#content>.content_row h3+.column_row,
#content>.content_row h4+.column_row,
#content>.content_row h5+.column_row,
#content>.content_row p+.column_row,
#content>.content_row ul+.column_row,
#content>.content_row ol+.column_row,
#content>.content_row dl+.column_row,
#content>.content_row hr+.column_row{
    margin-top:-10px
}

#content>.content_row .contentHeader{
    max-width:708px;
    margin:0 auto 60px auto;
    text-align:center
}

#content>.content_row .contentHeader:only-child{
    margin-bottom:0!important
}

#content>.content_row .contentHeader mark{
    background-color:rgba(74,86,105,0.1)
}

#content>.content_row .contentHeader.contentHeader-align-left,
#content>.content_row .contentHeader.contentHeader-large.contentHeader-align-left p{
    text-align:left;
    margin-left:0
}

#content>.content_row .contentHeader.contentHeader-align-right,
#content>.content_row .contentHeader.contentHeader-large.contentHeader-align-right p{
    text-align:right;
    margin-right:0
}

#content>.content_row .contentHeader.contentHeader-large{
    max-width:100%
}

#content>.content_row .contentHeader.contentHeader-large h1,
#content>.content_row .contentHeader.contentHeader-large h2,
#content>.content_row .contentHeader.contentHeader-large h3{
    font-weight:bold
}

#content>.content_row .contentHeader.contentHeader-large h1{
    font-size:62px;
    line-height:72px;
    letter-spacing:-1px
}


#content>.content_row .contentHeader.contentHeader-large h2{
    font-size:52px;
    line-height:62px
}


#content>.content_row .contentHeader.contentHeader-large h3{
    font-size:42px;
    line-height:52px
}


#content>.content_row .contentHeader.contentHeader-large p{
    max-width:708px;
    margin-left:auto;
    margin-right:auto;
    font-size:24px;
    line-height:36px;
    font-family:Montserrat,sans-serif
}

#content>.content_row .contentHeader.contentHeader-small p{
    margin-top:0
}

#content>.content_row .contentHeader.contentHeader-uppercase h1,
#content>.content_row .contentHeader.contentHeader-uppercase h2,
#content>.content_row .contentHeader.contentHeader-uppercase h3,
#content>.content_row .contentHeader.contentHeader-uppercase h4,
#content>.content_row .contentHeader.contentHeader-uppercase h5{
    font-weight:bold;
    text-transform:uppercase
}

#content>.content_row .content-box{
    padding:58px;
    background-color:#fff;
    border:2px solid #e6e9ee;
    border-radius:12px
}

#content>.content_row .content-box:not(:first-child){
    margin-top:60px
}

#content>.content_row .column_row>.content-sidebar-left,
#content>.content_row .column_row>.content-sidebar-right{
    position:relative
}

#content>.content_row .column_row>.content-sidebar-left{
    -webkit-box-ordinal-group:0;
    -ms-flex-order:-1;
    order:-1;
    padding-right:90px
}

#content>.content_row .column_row>.content-sidebar-right{
    -webkit-box-ordinal-group:2;
    -ms-flex-order:1;
    order:1;
    padding-left:90px
}

#content>.content_row .column_row>.content-sidebar-left.column-20,
#content>.content_row .column_row>.content-sidebar-right.column-20{
    min-width:268px
}

#content>.content_row .column_row>.content-sidebar-left.column-25,
#content>.content_row .column_row>.content-sidebar-right.column-25{
    min-width:335px
}

#content>.content_row .column_row>.content-sidebar-left.column-30,
#content>.content_row .column_row>.content-sidebar-right.column-30{
    min-width:402px
}

#content>.content_row .column_row>.content-sidebar-left.column-33,
#content>.content_row .column_row>.content-sidebar-right.column-33,
#content>.content_row .column_row>.content-sidebar-left.column-40,
#content>.content_row .column_row>.content-sidebar-right.column-40{
    min-width:445px
}

#content>.content_row .column_row>.content-sidebar-left.column-50{
    padding-right:30px;
    margin-right:60px
}

#content>.content_row .column_row>.content-sidebar-right.column-50{
    padding-left:30px;
    margin-left:60px
}

#content>.content_row .column_row>.content-sidebar-left::before,
#content>.content_row .column_row>.content-sidebar-right::before{
    content:"";
    position:absolute;
    top:-30px;
    bottom:-30px;
    border-left:2px solid #e6e9ee
}

#content>.content_row .column_row>.content-sidebar-left::before{
    right:29px
}

#content>.content_row .column_row>.content-sidebar-right::before{
    left:29px
}

#content>.content_row .column_row>.content-sidebar-left.column-50::before{
    right:-31px
}

#content>.content_row .column_row>.content-sidebar-right.column-50::before{
    left:-31px
}

#content>.content_row .content-background{
    position:absolute;
    overflow:hidden;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}

#content>.content_row.content_row-split-left .content-background,
#content>.content_row.content_row-split-right .content-background{
    z-index:4
}

#content>.content_row.content_row-split-left .content-background{
    left:50%
}

#content>.content_row.content_row-split-right .content-background{
    right:50%
}

#content>.content_row .content-background.content-background-scroll{
    top:-20%;
    bottom:-20%
}

#content>.content_row .content-background i{
    font-size:50vmin;
    text-align:center
}

#content>.content_row .content-background svg,
#content>.content_row .content-background img,
#content>.content_row .content-background video,
#content>.content_row .content-background iframe{
    position:absolute;
    top:50%;
    left:50%;
    width:100%;
    height:auto;
    max-width:none;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%)
}

#content>.content_row .content-background iframe{
    height:100%
}

#content>.content_row .content-background.object-fit-width svg,
#content>.content_row .content-background.object-fit-width img,
#content>.content_row .content-background.object-fit-width video{
    width:100%!important;
    height:auto!important
}

#content>.content_row .content-background.object-fit-height svg,
#content>.content_row .content-background.object-fit-height img,
#content>.content_row .content-background.object-fit-height video{
    width:auto!important;
    height:100%!important
}

#content>.content_row .content-background.content-background-scale svg,
#content>.content_row .content-background.content-background-scale img,
#content>.content_row .content-background.content-background-scale video{
    width:auto;
    height:auto
}

#content>.content_row .content-background.content-background-light i,
#content>.content_row .content-background.content-background-light svg{
    opacity:.1
}

#content>.content_row .content-background.content-background-light img,
#content>.content_row .content-background.content-background-light video,
#content>.content_row .content-background.content-background-light iframe{
    opacity:.2
}

#content>.content_row .content-background.content-background-blur i,
#content>.content_row .content-background.content-background-blur svg,
#content>.content_row .content-background.content-background-blur img,
#content>.content_row .content-background.content-background-blur video,
#content>.content_row .content-background.content-background-blur iframe{
    margin:-40px;
    -webkit-filter:blur(20px);
    filter:blur(20px)
}

#content>.content_row .content-slider{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
    -ms-flex-flow:row;
    flex-flow:row;
    margin:-60px 0
}

#content>.content_row .content-slider>ul{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    position:absolute;
    z-index:4;
    bottom:60px;
    width:100%;
    height:12px;
    line-height:12px;
    list-style-type:none;
    margin:0!important
}


#content>.content_row .content-slider>ul li{
    display:block;
    margin:0 20px;
    cursor:pointer;
    color:#a0abbc;
    font-size:14px;
    font-weight:bold;
    text-align:center;
    text-transform:uppercase;
    font-family:Montserrat,sans-serif;
    -webkit-transition:color .2s;
    transition:color .2s;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}

#content>.content_row .content-slider>ul li:hover{
    color:#4a5669
}

#content>.content_row .content-slider>ul li.active{
    color:#0098ef
}

#content>.content_row .content-slider>ul li:empty{
    width:12px;
    height:12px;
    margin:0 8px;
    border-radius:6px;
    background-color:rgba(74,86,105,0.1);
    -webkit-transition:background-color .2s;
    transition:background-color .2s
}

#content>.content_row .content-slider>ul li.active:empty{
    background-color:#0098ef
}

#content>.content_row .content-slider>i{
    position:absolute;
    z-index:2;
    top:50%;
    width:60px;
    height:60px;
    margin-top:-30px;
    line-height:60px;
    font-size:40px;
    color:#a0abbc;
    text-align:center;
    cursor:pointer;
    -webkit-transition:color .2s;
    transition:color .2s;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}

#content>.content_row .content-slider>i:hover{
    color:#4a5669
}

#content>.content_row .content-slider>i.slide-previous{
    left:0
}

#content>.content_row .content-slider>i.slide-next{
    right:0
}

#content>.content_row .content-slider .content-slider-inner,
#content>.content_row .content-slider .content-slider-inner .slide{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%
}

#content>.content_row .content-slider .content-slider-inner .slide{
    overflow:hidden;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-flex:0;
    -ms-flex:0 0 100%;
    flex:0 0 100%;
    padding:120px 0;
    color:#4a5669;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    text-decoration:none;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}

#content>.content_row .content-slider .content-slider-inner .slide.align-top{
    -webkit-box-align:start;
    -ms-flex-align:start;
    align-items:flex-start
}

#content>.content_row .content-slider .content-slider-inner .slide.align-bottom{
    -webkit-box-align:end;
    -ms-flex-align:end;
    align-items:flex-end
}

#content>.content_row .content-slider.content-slider-has-nav .content-slider-inner .slide{
    padding-bottom:190px
}

#content>.content_row .content-slider .content-slider-inner .slide .contentHeader.contentHeader-large{
    margin-bottom:50px
}

#content>.content_row .content-slider .content-slider-inner .slide .contentHeader.contentHeader-large p{
    margin-top:30px
}

#content>.content_row .content-slider .content-slider-inner .slide>.container{
    margin:0
}

#content>.content_row .content-slider .content-slider-inner .slide.active,
#content>.content_row .content-slider .content-slider-inner .slide.active-first{
    visibility:visible
}

#content>.content_row .content-slider .content-slider-inner .slide.inactive,
#content>.content_row .content-slider .content-slider-inner .slide:not(.active):not(.active-first){
    visibility:hidden
}

#content>.content_row .content-slider.animate-container-fade .content-slider-inner .slide.inactive,
#content>.content_row .content-slider.animate-container-slide .content-slider-inner .slide.inactive,
#content>.content_row .content-slider.animate-container-scale .content-slider-inner .slide.inactive,
#content>.content_row .content-slider.animate-background-fade .content-slider-inner .slide.inactive,
#content>.content_row .content-slider.animate-background-slide .content-slider-inner .slide.inactive,
#content>.content_row .content-slider.animate-background-scale .content-slider-inner .slide.inactive{
    -webkit-transition:visibility 0s linear .6s;
    transition:visibility 0s linear .6s
}

#content>.content_row .content-slider .content-slider-inner .slide.inactive>.container,
#content>.content_row .content-slider .content-slider-inner .slide.inactive>.content-background,
#content>.content_row .content-slider .content-slider-inner .slide:not(.active):not(.active-first)>.container,
#content>.content_row .content-slider .content-slider-inner .slide:not(.active):not(.active-first)>.content-background{
    opacity:0
}

#content>.content_row .content-slider.animate-container-fade .content-slider-inner .slide.active>.container,
#content>.content_row .content-slider.animate-background-fade .content-slider-inner .slide.active>.content-background{
    -webkit-animation:content-slider-animate-fade-in .6s;
    animation:content-slider-animate-fade-in .6s
}

#content>.content_row .content-slider.animate-container-fade .content-slider-inner .slide.inactive>.container,
#content>.content_row .content-slider.animate-background-fade .content-slider-inner .slide.inactive>.content-background{
    -webkit-animation:content-slider-animate-fade-out .6s;
    animation:content-slider-animate-fade-out .6s
}
@-webkit-keyframes content-slider-animate-fade-in{
    0%{
    opacity:0
}
100%{
    opacity:1
}

}
@keyframes content-slider-animate-fade-in{
    0%{
    opacity:0
}
100%{
    opacity:1
}

}
@-webkit-keyframes content-slider-animate-fade-out{
    0%{
    opacity:1
}
100%{
    opacity:0
}

}
@keyframes content-slider-animate-fade-out{
    0%{
    opacity:1
}
100%{
    opacity:0
}

}

#content>.content_row .content-slider.animate-container-slide .content-slider-inner .slide.active>.container,
#content>.content_row .content-slider.animate-background-slide .content-slider-inner .slide.active>.content-background{
    -webkit-animation:content-slider-animate-slide-in .6s;
    animation:content-slider-animate-slide-in .6s
}

#content>.content_row .content-slider.animate-container-slide .content-slider-inner .slide.inactive>.container,
#content>.content_row .content-slider.animate-background-slide .content-slider-inner .slide.inactive>.content-background{
    -webkit-animation:content-slider-animate-slide-out .6s;
    animation:content-slider-animate-slide-out .6s
}
@-webkit-keyframes content-slider-animate-slide-in{
    0%{
    opacity:0;
    -webkit-transform:translateX(25%);
    transform:translateX(25%)
}
100%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}

}
@keyframes content-slider-animate-slide-in{
    0%{
    opacity:0;
    -webkit-transform:translateX(25%);
    transform:translateX(25%)
}

100%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}

}

@-webkit-keyframes content-slider-animate-slide-out{
    0%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}
100%{
    opacity:0;
    -webkit-transform:translateX(-25%);
    transform:translateX(-25%)
}

}
@keyframes content-slider-animate-slide-out{
    0%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}
100%{
    opacity:0;
    -webkit-transform:translateX(-25%);
    transform:translateX(-25%)
}

}

#content>.content_row .content-slider.animate-container-slide .content-slider-inner .slide.active.animate-previous>.container,
#content>.content_row .content-slider.animate-background-slide .content-slider-inner .slide.active.animate-previous>.content-background{
    -webkit-animation:content-slider-animate-slide-in-previous .6s;
    animation:content-slider-animate-slide-in-previous .6s
}

#content>.content_row .content-slider.animate-container-slide .content-slider-inner .slide.inactive.animate-previous>.container,
#content>.content_row .content-slider.animate-background-slide .content-slider-inner .slide.inactive.animate-previous>.content-background{
    -webkit-animation:content-slider-animate-slide-out-previous .6s;
    animation:content-slider-animate-slide-out-previous .6s
}
@-webkit-keyframes content-slider-animate-slide-in-previous{
    0%{
    opacity:0;
    -webkit-transform:translateX(-25%);
    transform:translateX(-25%)
}
100%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}

}
@keyframes content-slider-animate-slide-in-previous{
    0%{
    opacity:0;
    -webkit-transform:translateX(-25%);
    transform:translateX(-25%)
}
100%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}

}
@-webkit-keyframes content-slider-animate-slide-out-previous{
    0%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}
100%{
    opacity:0;
    -webkit-transform:translateX(25%);
    transform:translateX(25%)
}

}
@keyframes content-slider-animate-slide-out-previous{
    0%{
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
}
100%{
    opacity:0;
    -webkit-transform:translateX(25%);
    transform:translateX(25%)
}

}

#content>.content_row .content-slider.animate-container-scale .content-slider-inner .slide.active>.container,
#content>.content_row .content-slider.animate-background-scale .content-slider-inner .slide.active>.content-background{
    -webkit-animation:content-slider-animate-scale-in .6s;
    animation:content-slider-animate-scale-in .6s
}

#content>.content_row .content-slider.animate-container-scale .content-slider-inner .slide.inactive>.container,
#content>.content_row .content-slider.animate-background-scale .content-slider-inner .slide.inactive>.content-background{
    -webkit-animation:content-slider-animate-scale-out .6s;
    animation:content-slider-animate-scale-out .6s
}
@-webkit-keyframes content-slider-animate-scale-in{
    0%{
    opacity:0;
    -webkit-transform:scale(0.8);
    transform:scale(0.8)
}
100%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}

}
@keyframes content-slider-animate-scale-in{
    0%{
    opacity:0;
    -webkit-transform:scale(0.8);
    transform:scale(0.8)
}
100%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}

}
@-webkit-keyframes content-slider-animate-scale-out{
    0%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}
100%{
    opacity:0;
    -webkit-transform:scale(1.2);
    transform:scale(1.2)
}

}
@keyframes content-slider-animate-scale-out{
    0%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}
100%{
    opacity:0;
    -webkit-transform:scale(1.2);
    transform:scale(1.2)
}

}

#content>.content_row .content-slider.animate-container-scale .content-slider-inner .slide.active.animate-previous>.container,
#content>.content_row .content-slider.animate-background-scale .content-slider-inner .slide.active.animate-previous>.content-background{
    -webkit-animation:content-slider-animate-scale-in-previous .6s;
    animation:content-slider-animate-scale-in-previous .6s
}

#content>.content_row .content-slider.animate-container-scale .content-slider-inner .slide.inactive.animate-previous>.container,
#content>.content_row .content-slider.animate-background-scale .content-slider-inner .slide.inactive.animate-previous>.content-background{
    -webkit-animation:content-slider-animate-scale-out-previous .6s;
    animation:content-slider-animate-scale-out-previous .6s
}
@-webkit-keyframes content-slider-animate-scale-in-previous{
    0%{
    opacity:0;
    -webkit-transform:scale(1.2);
    transform:scale(1.2)
}
100%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}

}
@keyframes content-slider-animate-scale-in-previous{
    0%{
    opacity:0;
    -webkit-transform:scale(1.2);
    transform:scale(1.2)
}
100%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}

}
@-webkit-keyframes content-slider-animate-scale-out-previous{
    0%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}
100%{
    opacity:0;
    -webkit-transform:scale(0.8);
    transform:scale(0.8)
}

}
@keyframes content-slider-animate-scale-out-previous{
    0%{
    opacity:1;
    -webkit-transform:scale(1);
    transform:scale(1)
}
100%{
    opacity:0;
    -webkit-transform:scale(0.8);
    transform:scale(0.8)
}

}

.column-row,
#content > .content-row .content-header + .column-row
{
	display: flex;
	margin: -30px;
}


#content>.content_row.content_row-gray,
#content>.content_row.content_row-gray.content_row-split-left::after,
#content>.content_row.content_row-gray.content_row-split-right::after{
    background-color:#f0f2f5
}


#content>.content_row.content_row-dark,
#content>.content_row.content_row-dark.content_row-split-left::after,
#content>.content_row.content_row-dark.content_row-split-right::after{
    background-color:#2c3645
}

#content>.content_row.content_row-color,
#content>.content_row.content_row-color.content_row-split-left::after,
#content>.content_row.content_row-color.content_row-split-right::after{
    background-color:#0098ef
}


#content>.content_row.content_row-gradient,
#content>.content_row.content_row-gradient.content_row-split-left::after,
#content>.content_row.content_row-gradient.content_row-split-right::after{
    background-repeat:repeat-x;
    background-position:center bottom;
    background-image:-webkit-gradient(linear,left top,left bottom,from(#fff),to(#f0f2f5));
    background-image:linear-gradient(#fff 0,#f0f2f5 100%)
}

#content>.content_row.content_row-gray.content_row-gradient,
#content>.content_row.content_row-gray.content_row-gradient.content_row-split-left::after,
#content>.content_row.content_row-gray.content_row-gradient.content_row-split-right::after{
    background-image:-webkit-gradient(linear,left top,left bottom,from(#f0f2f5),to(#e6e9ee));
    background-image:linear-gradient(#f0f2f5 0,#e6e9ee 100%)
}


#content>.content_row.content_row-dark.content_row-gradient,

#content>.content_row.content_row-dark.content_row-gradient.content_row-split-left::after,

#content>.content_row.content_row-dark.content_row-gradient.content_row-split-right::after{
    background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),to(rgba(0,0,0,0.2)));
    background-image:linear-gradient(rgba(0,0,0,0) 0,rgba(0,0,0,0.2) 100%)
}

#content>.content_row.content_row-color.content_row-gradient,
#content>.content_row.content_row-color.content_row-gradient.content_row-split-left::after,
#content>.content_row.content_row-color.content_row-gradient.content_row-split-right::after{
    background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),to(rgba(0,0,0,0.1)));
    background-image:linear-gradient(rgba(0,0,0,0) 0,rgba(0,0,0,0.1) 100%)
}

#content>.content_row.content_row.content_row-dark.content_row-border-top,
#content>.content_row.content_row.content_row-dark.content_row-border-bottom{
    border-color:#3a4659
}

#content>.content_row.content_row.content_row-color.content_row-border-top,
#content>.content_row.content_row.content_row-color.content_row-border-bottom{
    border-color:rgba(255,255,255,0.2)
}

#content>.content_row.content_row-gray.content_row_clouds::before,
#content>.content_row.content_row-dark.content_row_clouds::before,
#content>.content_row.content_row-color.content_row_clouds::before,
#content>.content_row.content_row-gradient.content_row_clouds::before{
    background-image:url(img/layout/clouds-light-left.png)
}

#content>.content_row.content_row-gray.content_row_clouds::after,
#content>.content_row.content_row-dark.content_row_clouds::after,
#content>.content_row.content_row-color.content_row_clouds::after,
#content>.content_row.content_row-gradient.content_row_clouds::after{
    background-image:url(img/layout/clouds-light-right.png)
}

#content>.content_row.content_row-dark.content_row_clouds.content_row_clouds-light::before,
#content>.content_row.content_row-dark.content_row_clouds.content_row_clouds-light::after{
    opacity:.1
}

#content>.content_row.content_row-color.content_row_clouds.content_row_clouds-light::before,
#content>.content_row.content_row-color.content_row_clouds.content_row_clouds-light::after{
    opacity:.2
}

#content>.content_row.content_row-dark,
#content>.content_row.content_row-dark .tab-group .tab-item.active .tab-item-title,
#content>.content_row.content_row-dark .tab-group:not(.tab-group-switch-style)>ul li.active,
#content>.content_row.content_row-dark .contentHeader mark,
#content>.content_row.content_row-dark .content-slider .content-slider-inner .slide,
#content>.content_row.content_row-dark .content-slider>ul li:hover:not(.active),
#content>.content_row.content_row-dark .content-slider>i:hover,
#content>.content_row.content_row-color,
#content>.content_row.content_row-color .tab-group .tab-item.active .tab-item-title,
#content>.content_row.content_row-color .tab-group:not(.tab-group-switch-style)>ul li.active,
#content>.content_row.content_row-color .contentHeader mark,
#content>.content_row.content_row-color .content-slider .content-slider-inner .slide,
#content>.content_row.content_row-color .content-slider>ul li:hover:not(.active),
#content>.content_row.content_row-color .content-slider>i:hover{
    color:#fff
}

#content>.content_row.content_row-dark blockquote footer,
#content>.content_row.content_row-dark dl dt,
#content>.content_row.content_row-dark .text-color-gray,
#content>.content_row.content_row-dark .blog-article .blog-article-header ul li,
#content>.content_row.content_row-dark .testimonial .testimonial-author small,
#content>.content_row.content_row-dark .blog-comment-group .blog-comment-author li,
#content>.content_row.content_row-dark .content-slider>ul li:not(.active),
#content>.content_row.content_row-dark .content-slider>i{
    color:#667791
}

#content>.content_row.content_row-color blockquote footer,
#content>.content_row.content_row-color dl dt,
#content>.content_row.content_row-color .text-color-gray,
#content>.content_row.content_row-color .blog-article .blog-article-header ul li,
#content>.content_row.content_row-color .testimonial .testimonial-author small,
#content>.content_row.content_row-color .blog-comment-group .blog-comment-author li,
#content>.content_row.content_row-color .content-slider>ul li:not(.active),
#content>.content_row.content_row-color .content-slider>i{
    color:rgba(255,255,255,0.6)
}

#content>.content_row.content_row-color a:not(.button),
#content>.content_row.content_row-color .icon-feature,
#content>.content_row.content_row-color .icon-feature-large,
#content>.content_row.content_row-color .icon-feature-inline,
#content>.content_row.content_row-color .content-slider>ul li.active,
#content>.content_row.content_row-color .tab-group .tab-item .tab-item-title,
#content>.content_row.content_row-color .tab-group:not(.tab-group-switch-style)>ul li{
    color:#fc3
}

#content>.content_row.content_row-color ul.list-style-nav li a:not(.button),
#content>.content_row.content_row-color table a:not(.button),
#content>.content_row.content_row-color pre a:not(.button),
#content>.content_row.content_row-color .pre-code a:not(.button),
#content>.content_row.content_row-color .tags li a:not(.button),
#content>.content_row.content_row-color .feature-box a:not(.button),
#content>.content_row.content_row-color .product-box a:not(.button),
#content>.content_row.content_row-color .product-slider a:not(.button),
#content>.content_row.content_row-color .product-configurator a:not(.button),
#content>.content_row.content_row-color .network-map ul li span.label-top-left,
#content>.content_row.content_row-color .network-map ul li span.label-top-right,
#content>.content_row.content_row-color .network-map ul li span.label-bottom-left,
#content>.content_row.content_row-color .network-map ul li span.label-bottom-right,
#content>.content_row.content_row-color .testimonial .testimonial-content a:not(.button),
#content>.content_row.content_row-color .blog-comment-group .blog-comment-content a:not(.button){
    color:#0098ef
}

#content>.content_row.content_row-color .tags li a:hover,
#content>.content_row.content_row-color .network-map ul li a:hover span.label-top-left,
#content>.content_row.content_row-color .network-map ul li a:hover span.label-top-right,
#content>.content_row.content_row-color .network-map ul li a:hover span.label-bottom-left,
#content>.content_row.content_row-color .network-map ul li a:hover span.label-bottom-right{
    color:#a0abbc
}


#content>.content_row.content_row-gray .testimonial .testimonial-content,

#content>.content_row.content_row-gray .testimonial .testimonial-author::before,

#content>.content_row.content_row-gray .blog-comment-group .blog-comment-content,

#content>.content_row.content_row-dark .testimonial .testimonial-content,

#content>.content_row.content_row-dark .testimonial .testimonial-author::before,

#content>.content_row.content_row-dark .blog-comment-group .blog-comment-content,

#content>.content_row.content_row-color .tags li a,

#content>.content_row.content_row-color .network-map ul li,

#content>.content_row.content_row-color .network-map ul li span.label-top-left,

#content>.content_row.content_row-color .network-map ul li span.label-top-right,

#content>.content_row.content_row-color .network-map ul li span.label-bottom-left,

#content>.content_row.content_row-color .network-map ul li span.label-bottom-right,

#content>.content_row.content_row-color .network-map ul li span.label-top-left::before,

#content>.content_row.content_row-color .network-map ul li span.label-top-right::before,

#content>.content_row.content_row-color .network-map ul li span.label-bottom-left::before,

#content>.content_row.content_row-color .network-map ul li span.label-bottom-right::before,

#content>.content_row.content_row-color .testimonial .testimonial-content,

#content>.content_row.content_row-color .testimonial .testimonial-author::before,

#content>.content_row.content_row-color .blog-comment-group .blog-comment-content,

#content>.content_row.content_row-color .content-slider>ul li.active:empty{
    background-color:#fff
}


#content>.content_row.content_row-dark .contentHeader mark,

#content>.content_row.content_row-dark .content-slider>ul li:empty:not(.active),

#content>.content_row.content_row-color code,
#content>.content_row.content_row-color table caption,

#content>.content_row.content_row-color .pagination>li:not(.active) a:hover,

#content>.content_row.content_row-color .tab-group:not(.tab-group-switch-style)>ul li:not(.active):hover,

#content>.content_row.content_row-color .tab-group .tab-item:not(.active) .tab-item-title:hover,

#content>.content_row.content_row-color .feature-box .feature-header,

#content>.content_row.content_row-color .product-box .product-popular,

#content>.content_row.content_row-color .contentHeader mark,

#content>.content_row.content_row-color .content-slider>ul li:empty:not(.active){
    background-color:rgba(255,255,255,0.2)
}


#content>.content_row.content_row-dark .content-box,
#content>.content_row.content_row-dark .tab-group .tab-item .tab-item-title,

#content>.content_row.content_row-dark .tab-group:not(.tab-group-switch-style)>ul li,

#content>.content_row.content_row-dark .tab-group:not(.tab-group-switch-style)>ul li::after,

#content>.content_row.content_row-dark .tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner{
    background-color:#2c3645
}


#content>.content_row.content_row-color .content-box,

#content>.content_row.content_row-color .tab-group .tab-item .tab-item-title,

#content>.content_row.content_row-color .tab-group:not(.tab-group-switch-style)>ul li,

#content>.content_row.content_row-color .tab-group:not(.tab-group-switch-style)>ul li::after,

#content>.content_row.content_row-color .tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner{
    background-color:#0098ef
}


#content>.content_row.content_row-dark .feature-box .feature-header,

#content>.content_row.content_row-dark .pagination>li:not(.active) a:hover,

#content>.content_row.content_row-dark .tab-group:not(.tab-group-switch-style)>ul li:not(.active):hover,

#content>.content_row.content_row-dark .tab-group .tab-item:not(.active) .tab-item-title:hover{
    background-color:#3a4659
}


#content>.content_row.content_row-dark .pagination>li a,

#content>.content_row.content_row-color .pagination>li a{
    background-color:transparent
}


#content>.content_row.content_row-dark ul.list-style-nav,

#content>.content_row.content_row-dark table,

#content>.content_row.content_row-dark pre,

#content>.content_row.content_row-dark .pre-code,
#content>.content_row.content_row-dark .feature-box .feature-content,
#content>.content_row.content_row-dark .product-box,
#content>.content_row.content_row-dark .product-slider,
#content>.content_row.content_row-dark .product-configurator,
#content>.content_row.content_row-dark .tab-group.tab-group-switch-style>ul,
#content>.content_row.content_row-color ul.list-style-nav,
#content>.content_row.content_row-color table,
#content>.content_row.content_row-color pre,
#content>.content_row.content_row-color .pre-code,
#content>.content_row.content_row-color .product-box,
#content>.content_row.content_row-color .feature-box .feature-content,
#content>.content_row.content_row-color .product-slider,
#content>.content_row.content_row-color .product-configurator,
#content>.content_row.content_row-color .tab-group.tab-group-switch-style>ul{
    border:0
}

#content>.content_row.content_row-dark blockquote::before,
#content>.content_row.content_row-dark hr,
#content>.content_row.content_row-dark form fieldset,
#content>.content_row.content_row-dark .pagination>li:not(.active) a,
#content>.content_row.content_row-dark .content-box,
#content>.content_row.content_row-dark .blog-article:not(:first-child),
#content>.content_row.content_row-dark .tab-group .tab-item .tab-item-title,
#content>.content_row.content_row-dark .tab-group:not(.tab-group-switch-style)>ul li,
#content>.content_row.content_row-dark .tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner,
#content>.content_row.content_row-dark .column_row>.content-sidebar-left::before,
#content>.content_row.content_row-dark .column_row>.content-sidebar-right::before{
    border-color:#3a4659
}

#content>.content_row.content_row-color blockquote::before,
#content>.content_row.content_row-color hr,
#content>.content_row.content_row-color form fieldset,
#content>.content_row.content_row-color .pagination>li:not(.active) a,
#content>.content_row.content_row-color .content-box,
#content>.content_row.content_row-color .blog-article:not(:first-child),
#content>.content_row.content_row-color .tab-group .tab-item .tab-item-title,
#content>.content_row.content_row-color .tab-group:not(.tab-group-switch-style)>ul li,
#content>.content_row.content_row-color .tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner,
#content>.content_row.content_row-color .column_row>.content-sidebar-left::before,
#content>.content_row.content_row-color .column_row>.content-sidebar-right::before{
    border-color:rgba(255,255,255,0.2)
}


#content>.content_row.content_row-color .pagination>li.active a{
    border-color:#fc3
}


#content>.content_row.content_row-dark .icon-feature-large{
    text-shadow:8px 8px 0 rgba(0,0,0,0.1)
}

#content>.content_row.content_row-color .icon-feature-large{
    text-shadow:8px 8px 0 rgba(74,86,105,0.2)
}

#content>.content_row.content_row-dark svg.icon-feature-large{
    -webkit-filter:drop-shadow(8px 8px 0 rgba(0,0,0,0.1));
    filter:drop-shadow(8px 8px 0 rgba(0,0,0,0.1))
}

#content>.content_row.content_row-color svg.icon-feature-large{
    -webkit-filter:drop-shadow(8px 8px 0 rgba(74,86,105,0.2));
    filter:drop-shadow(8px 8px 0 rgba(74,86,105,0.2))
}

#content>.content_row.content_row-dark .text-color-gray{
    color:#667791!important
}

#content>.content_row.content_row-color .text-color-gray{
    color:rgba(255,255,255,0.6)!important
}

#content>.content_row.content_row-dark input:not(.no-custom-style):not([type="checkbox"]):not([type="radio"]),
#content>.content_row.content_row-dark select:not(.no-custom-style),
#content>.content_row.content_row-dark textarea:not(.no-custom-style),
#content>.content_row.content_row-color input:not(.no-custom-style):not([type="checkbox"]):not([type="radio"]),
#content>.content_row.content_row-color select:not(.no-custom-style),
#content>.content_row.content_row-color textarea:not(.no-custom-style){
    padding:14px 17px;
    border:0
}

#content>.content_row.content_row-dark form .checkbox-style,
#content>.content_row.content_row-dark form .radio-style,
#content>.content_row.content_row-color form .checkbox-style,
#content>.content_row.content_row-color form .radio-style{
    margin-top:0;
    border:0
}

#content>.content_row.content_row-dark form .checkbox-style-block .checkbox-style,
#content>.content_row.content_row-dark form .radio-style-block .radio-style,
#content>.content_row.content_row-color form .checkbox-style-block .checkbox-style,
#content>.content_row.content_row-color form .radio-style-block .radio-style{
    top:8px
}

#content>.content_row.content_row-dark form .checkbox-style-block label,
#content>.content_row.content_row-dark form .radio-style-block label,
#content>.content_row.content_row-color form .checkbox-style-block label,
#content>.content_row.content_row-color form .radio-style-block label{
    padding-left:48px
}

#content>.content_row.content_row-dark .product-box .product-popular,
#content>.content_row.content_row-color .product-box .product-popular{
    left:0;
    right:0
}

#content>.content_row.content_row-color .content-background.content-background-light i,
#content>.content_row.content_row-color .content-background.content-background-light svg,
#content>.content_row.content_row-color .network-map img{
    opacity:.2
}

#content>.content_row.content_row-gray .blog-comment-group .blog-comment-author::before,
#content>.content_row.content_row-dark .blog-comment-group .blog-comment-author::before,
#content>.content_row.content_row-color .blog-comment-group .blog-comment-author::before{
    border-top-color:#fff
}

/* Content Header */
#content > .content-row .content-header
{
	max-width: 708px;
	margin: 0 auto 60px auto;
	text-align: center;
}

#content > .content-row .content-header:only-child
{
	margin-bottom: 0 !important;
}

#content > .content-row .content-header mark
{
	background-color: rgba(74, 86, 105, 0.1);
}

#content > .content-row .content-header.content-header-align-left,
#content > .content-row .content-header.content-header-large.content-header-align-left p
{
	text-align: left;
	margin-left: 0;
}

#content > .content-row .content-header.content-header-align-right,
#content > .content-row .content-header.content-header-large.content-header-align-right p
{
	text-align: right;
	margin-right: 0;
}

#content > .content-row .content-header.content-header-large
{
	max-width: 100%;
}

#content > .content-row .content-header.content-header-large h1,
#content > .content-row .content-header.content-header-large h2,
#content > .content-row .content-header.content-header-large h3
{
	font-weight: bold;
}

#content > .content-row .content-header.content-header-large h1
{
	font-size: 62px;
	line-height: 72px;
	letter-spacing: -1px;
}

#content > .content-row .content-header.content-header-large h2
{
	font-size: 52px;
	line-height: 62px;
}

#content > .content-row .content-header.content-header-large h3
{
	font-size: 42px;
	line-height: 52px;
}

#content > .content-row .content-header.content-header-large p
{
	max-width: 708px;
	margin-left: auto;
	margin-right: auto;
	font-size: 24px;
	line-height: 36px;
	font-family: Montserrat, sans-serif;
}

#content > .content-row .content-header.content-header-small p
{
	margin-top: 0;
}

#content > .content-row .content-header.content-header-uppercase h1,
#content > .content-row .content-header.content-header-uppercase h2,
#content > .content-row .content-header.content-header-uppercase h3,
#content > .content-row .content-header.content-header-uppercase h4,
#content > .content-row .content-header.content-header-uppercase h5
{
	font-weight: bold;
	text-transform: uppercase;
}



/* Content Colors - Text */
#content > .content-row.content-row-dark,
#content > .content-row.content-row-dark .tab-group .tab-item.active .tab-item-title,
#content > .content-row.content-row-dark .tab-group:not(.tab-group-switch-style) > ul li.active,
#content > .content-row.content-row-dark .content-header mark,
#content > .content-row.content-row-dark .content-slider .content-slider-inner .slide,
#content > .content-row.content-row-dark .content-slider > ul li:hover:not(.active),
#content > .content-row.content-row-dark .content-slider > i:hover,
#content > .content-row.content-row-color,
#content > .content-row.content-row-color .tab-group .tab-item.active .tab-item-title,
#content > .content-row.content-row-color .tab-group:not(.tab-group-switch-style) > ul li.active,
#content > .content-row.content-row-color .content-header mark,
#content > .content-row.content-row-color .content-slider .content-slider-inner .slide,
#content > .content-row.content-row-color .content-slider > ul li:hover:not(.active),
#content > .content-row.content-row-color .content-slider > i:hover
{
	color: #fff;
}

#content > .content-row.content-row-dark blockquote footer,
#content > .content-row.content-row-dark dl dt,
#content > .content-row.content-row-dark .text-color-gray,
#content > .content-row.content-row-dark .blog-article .blog-article-header ul li,
#content > .content-row.content-row-dark .testimonial .testimonial-author small,
#content > .content-row.content-row-dark .blog-comment-group .blog-comment-author li,
#content > .content-row.content-row-dark .content-slider > ul li:not(.active),
#content > .content-row.content-row-dark .content-slider > i
{
	color: #667791;
}

#content > .content-row.content-row-color blockquote footer,
#content > .content-row.content-row-color dl dt,
#content > .content-row.content-row-color .text-color-gray,
#content > .content-row.content-row-color .blog-article .blog-article-header ul li,
#content > .content-row.content-row-color .testimonial .testimonial-author small,
#content > .content-row.content-row-color .blog-comment-group .blog-comment-author li,
#content > .content-row.content-row-color .content-slider > ul li:not(.active),
#content > .content-row.content-row-color .content-slider > i
{
	color: rgba(255, 255, 255, 0.6);
}

#content > .content-row.content-row-color a:not(.button),
#content > .content-row.content-row-color .icon-feature,
#content > .content-row.content-row-color .icon-feature-large,
#content > .content-row.content-row-color .icon-feature-inline,
#content > .content-row.content-row-color .content-slider > ul li.active,
#content > .content-row.content-row-color .tab-group .tab-item .tab-item-title,
#content > .content-row.content-row-color .tab-group:not(.tab-group-switch-style) > ul li
{
	color: #ffcc33;
}

#content > .content-row.content-row-color ul.list-style-nav li a:not(.button),
#content > .content-row.content-row-color table a:not(.button),
#content > .content-row.content-row-color pre a:not(.button),
#content > .content-row.content-row-color .pre-code a:not(.button),
#content > .content-row.content-row-color .tags li a:not(.button),
#content > .content-row.content-row-color .feature-box a:not(.button),
#content > .content-row.content-row-color .product-box a:not(.button),
#content > .content-row.content-row-color .product-slider a:not(.button),
#content > .content-row.content-row-color .product-configurator a:not(.button),
#content > .content-row.content-row-color .network-map ul li span.label-top-left,
#content > .content-row.content-row-color .network-map ul li span.label-top-right,
#content > .content-row.content-row-color .network-map ul li span.label-bottom-left,
#content > .content-row.content-row-color .network-map ul li span.label-bottom-right,
#content > .content-row.content-row-color .testimonial .testimonial-content a:not(.button),
#content > .content-row.content-row-color .blog-comment-group .blog-comment-content a:not(.button)
{
	color: #0098ef;
}

#content > .content-row.content-row-color .tags li a:hover,
#content > .content-row.content-row-color .network-map ul li a:hover span.label-top-left,
#content > .content-row.content-row-color .network-map ul li a:hover span.label-top-right,
#content > .content-row.content-row-color .network-map ul li a:hover span.label-bottom-left,
#content > .content-row.content-row-color .network-map ul li a:hover span.label-bottom-right
{
	color: #a0abbc;
}

/* Content Colors - Backgrounds */
#content > .content-row.content-row-gray .testimonial .testimonial-content,
#content > .content-row.content-row-gray .testimonial .testimonial-author::before,
#content > .content-row.content-row-gray .blog-comment-group .blog-comment-content,
#content > .content-row.content-row-dark .testimonial .testimonial-content,
#content > .content-row.content-row-dark .testimonial .testimonial-author::before,
#content > .content-row.content-row-dark .blog-comment-group .blog-comment-content,
#content > .content-row.content-row-color .tags li a,
#content > .content-row.content-row-color .network-map ul li,
#content > .content-row.content-row-color .network-map ul li span.label-top-left,
#content > .content-row.content-row-color .network-map ul li span.label-top-right,
#content > .content-row.content-row-color .network-map ul li span.label-bottom-left,
#content > .content-row.content-row-color .network-map ul li span.label-bottom-right,
#content > .content-row.content-row-color .network-map ul li span.label-top-left::before,
#content > .content-row.content-row-color .network-map ul li span.label-top-right::before,
#content > .content-row.content-row-color .network-map ul li span.label-bottom-left::before,
#content > .content-row.content-row-color .network-map ul li span.label-bottom-right::before,
#content > .content-row.content-row-color .testimonial .testimonial-content,
#content > .content-row.content-row-color .testimonial .testimonial-author::before,
#content > .content-row.content-row-color .blog-comment-group .blog-comment-content,
#content > .content-row.content-row-color .content-slider > ul li.active:empty
{
	background-color: #fff;
}

#content > .content-row.content-row-dark .content-header mark,
#content > .content-row.content-row-dark .content-slider > ul li:empty:not(.active),
#content > .content-row.content-row-color code,
#content > .content-row.content-row-color table caption,
#content > .content-row.content-row-color .pagination > li:not(.active) a:hover,
#content > .content-row.content-row-color .tab-group:not(.tab-group-switch-style) > ul li:not(.active):hover,
#content > .content-row.content-row-color .tab-group .tab-item:not(.active) .tab-item-title:hover,
#content > .content-row.content-row-color .feature-box .feature-header,
#content > .content-row.content-row-color .product-box .product-popular,
#content > .content-row.content-row-color .content-header mark,
#content > .content-row.content-row-color .content-slider > ul li:empty:not(.active)
{
	background-color: rgba(255, 255, 255, 0.2);
}

#content > .content-row.content-row-dark .content-box,
#content > .content-row.content-row-dark .tab-group .tab-item .tab-item-title,
#content > .content-row.content-row-dark .tab-group:not(.tab-group-switch-style) > ul li,
#content > .content-row.content-row-dark .tab-group:not(.tab-group-switch-style) > ul li::after,
#content > .content-row.content-row-dark .tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner
{
	background-color: #2c3645;
}

#content > .content-row.content-row-color .content-box,
#content > .content-row.content-row-color .tab-group .tab-item .tab-item-title,
#content > .content-row.content-row-color .tab-group:not(.tab-group-switch-style) > ul li,
#content > .content-row.content-row-color .tab-group:not(.tab-group-switch-style) > ul li::after,
#content > .content-row.content-row-color .tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner
{
	background-color: #0098ef;
}

#content > .content-row.content-row-dark .feature-box .feature-header,
#content > .content-row.content-row-dark .pagination > li:not(.active) a:hover,
#content > .content-row.content-row-dark .tab-group:not(.tab-group-switch-style) > ul li:not(.active):hover,
#content > .content-row.content-row-dark .tab-group .tab-item:not(.active) .tab-item-title:hover
{
	background-color: #3a4659;
}

#content > .content-row.content-row-dark .pagination > li a,
#content > .content-row.content-row-color .pagination > li a
{
	background-color: transparent;
}

#footer{
    padding:30px 0;
    color:#a0abbc
}
.footer-gray{
    background-color:#f0f2f5
}
.footer-dark{
    background-color:#2c3645
}
.footer-color{
    background-color:#0098ef
}
.footer-dark #footer{
    color:#667791
}
.footer-color #footer{
    color:rgba(255,255,255,0.6)
}
#footer .footer-primary{
    padding:30px 0
}
#footer .footer-primary h5{
    padding-bottom:6px;
    font-size:14px;
    font-weight:bold;
    text-transform:uppercase
}
.footer-dark #footer a:not(.button),
.footer-dark #footer .footer-primary ul li a:hover,
.footer-color #footer a:not(.button),
.footer-color #footer .footer-primary ul li a:hover{
    color:#fff
}
#footer .footer-primary p:not(:first-child){
    margin-top:14px
}
#footer .footer-primary ul{
    margin-top:10px;
    list-style-type:none
}
#footer .footer-primary ul li a{
    display:inline-block;
    padding:4px 10px;
    margin-left:-10px;
    line-height:28px;
    font-size:15px;
    border-radius:4px
}
#footer .footer-primary ul li a:hover{
    color:#a0abbc;
    text-decoration:none;
    background-color:#f0f2f5
}
.footer-gray #footer .footer-primary ul li a:hover{
    background-color:#e6e9ee
}
.footer-dark #footer .footer-primary ul li a:hover{
    background-color:#3c4655
}
.footer-color #footer .footer-primary ul li a:hover{
    background-color:rgba(255,255,255,0.2)
}
#footer .footer-primary ul li ul{
    margin-top:0;
    padding:5px 0 5px 20px
}
#footer .footer-primary ul.list-style-icon li{
    padding-left:0
}
#footer .footer-primary ul.list-style-icon li a{
    position:relative;
    padding-left:34px
}
#footer .footer-primary ul.list-style-icon li .fa,#footer .footer-primary ul.list-style-icon li .fas,#footer .footer-primary ul.list-style-icon li .far,#footer .footer-primary ul.list-style-icon li .fal,#footer .footer-primary ul.list-style-icon li .fab,#footer .footer-primary ul.list-style-icon li .svg-inline--fa,#footer .footer-primary ul.list-style-icon li .material-icons{
    left:8px
}
#footer .footer-primary ul.list-style-icon li .svg-inline--fa{
    top:10px
}
#footer .footer-secondary:not(:first-child){
    padding-top:30px;
    margin-top:30px;
    border-top:2px solid #e6e9ee
}
.footer-dark #footer .footer-secondary:not(:first-child){
    border-color:#3a4659
}
.footer-color #footer .footer-secondary:not(:first-child){
    border-color:rgba(255,255,255,0.2)
}
#footer .footer-secondary p{
    font-size:14px;
    text-align:center
}

@media(max-width:1340px){
    .container,
    .container.container-full-width,
    #content>.content_row .content-slider .content-slider-inner .slide>.container{
        width:auto;
        margin:0 30px;
        -ms-flex-preferred-size:100%;
        flex-basis:100%
    }

#content>.content_row.content_row-split-left .container:not(.container-full-width),
#content>.content_row.content_row-split-right .container:not(.container-full-width){
    width:50%;
    -ms-flex-preferred-size:50%;
    flex-basis:50%
}

#content>.content_row.content_row-split-left .container:not(.container-full-width){
    padding:0 60px 0 30px;
    margin:0 auto 0 0
}

#content>.content_row.content_row-split-right .container:not(.container-full-width){
    padding:0 30px 0 60px;
    margin:0 0 0 auto
}

#content>.content_row.content_row-split-left .container.container-full-width{
    padding-left:30px
}

#content>.content_row.content_row-split-right .container.container-full-width{
    padding-right:30px
}


}


@media(max-width:1024px){
    
    #content>.content_row.content_row-split-left .container:not(.container-full-width),
    #content>.content_row.content_row-split-left .container.container-full-width{
        padding-right:30px
    }

#content>.content_row.content_row-split-right .container:not(.container-full-width),
#content>.content_row.content_row-split-right .container.container-full-width{
    padding-left:30px
}
#header .logo,#nav-overlay .logo{
    max-width:100%;
    margin-right:60px
}
#header:not(.header-nav-overlay) .logo .logo-text.logo-text-baseline{
    padding-top:0
}
#header nav{
    display:none
}
#nav-overlay:not(.nav-overlay-visible),#nav-overlay-open:not(.nav-overlay-visible),#nav-overlay-close:not(.nav-overlay-visible),#nav-overlay-background:not(.nav-overlay-visible){
    display:block
}
#notification[data-dismissible="true"] p{
    margin-left:0;
    margin-right:64px;
    text-align:left
}
#notification p a.text-margin-left{
    margin-left:0
}
#notification-dismiss{
    right:6px
}
.tab-group>a{
    top:-30px
}
.blog-article:not(:first-child){
    padding-top:20px;
    margin-top:30px
}
.blog-article .blog-article-content{
    margin-top:20px
}
.pagination:not(:first-child),
.tab-group.tab-group-switch-style .tab-item .tab-item-inner{
    margin-top:30px!important
}
.product-slider:not(.product-slider-small)>ul li{
    width:50%!important;
    text-align:center
}
.product-slider:not(.product-slider-small)>ul li:last-of-type{
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.product-slider:not(.product-slider-small)>ul li:last-of-type .price{
    text-align:left
}
.product-slider:not(.product-slider-small)>ul li:first-child:nth-last-child(3):last-of-type,
.product-slider:not(.product-slider-small)>ul li:first-child:nth-last-child(3) ~ li:last-of-type,
.product-slider:not(.product-slider-small)>ul li:first-child:nth-last-child(5):last-of-type,
.product-slider:not(.product-slider-small)>ul li:first-child:nth-last-child(5) ~ li:last-of-type{
    width:100%!important;
    margin-top:30px
}
.column_row,
#content>.content_row .contentHeader+.column_row{
    margin:-15px
}
.column_row:not(:first-child){
    margin-top:15px
}

#content>.content_row h1+.column_row,
#content>.content_row h2+.column_row,
#content>.content_row h3+.column_row,
#content>.content_row h4+.column_row,
#content>.content_row h5+.column_row,
#content>.content_row p+.column_row,
#content>.content_row ul+.column_row,
#content>.content_row ol+.column_row,
#content>.content_row dl+.column_row,
#content>.content_row hr+.column_row{
    margin-top:5px
}
.column_row>.column-20,
.column_row>.column-25,
.column_row>.column-30,
.column_row>.column-33,
.column_row>.column-40,
.column_row>.column-50,
.column_row>.column-60,
.column_row>.column-66,
.column_row>.column-70,
.column_row>.column-75,
.column_row>.column-80,
.column_row>.column-flex{
    padding:15px
}

#content>.content_row{
    padding:30px 0
}
.product-slider,
.product-configurator,
#content>.content_row .content-box,
.tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner{
    padding:28px
}

#content>.content_row .content-box:not(:first-child),
.product-configurator .configurator-row:not(:first-child),
.product-configurator .configurator-order:not(:first-child){
    margin-top:30px
}

#content>.content_row .content-slider{
    margin:-30px 0
}

#content>.content_row .contentHeader{
    margin-bottom:30px
}

#content>.content_row .column_row>.content-sidebar-left{
    padding-right:45px
}

#content>.content_row .column_row>.content-sidebar-right{
    padding-left:45px
}

#content>.content_row .column_row>.content-sidebar-left.column-50{
    padding-right:15px;
    margin-right:30px
}

#content>.content_row .column_row>.content-sidebar-right.column-50{
    padding-left:15px;
    margin-left:30px
}

#content>.content_row .column_row>.content-sidebar-left::before{
    right:14px
}

#content>.content_row .column_row>.content-sidebar-right::before{
    left:14px
}

#content>.content_row .column_row>.content-sidebar-left.column-50::before{
    right:-16px
}

#content>.content_row .column_row>.content-sidebar-right.column-50::before{
    left:-16px
}
#footer,#footer .footer-primary{
    padding:15px 0
}
#footer .footer-secondary:not(:first-child){
    padding-top:15px;
    margin-top:15px
}

}

@media(max-width:960px){
    table:not(.table-layout-static) tr td,table:not(.table-layout-static) tr th,table:not(.table-layout-static) tr .responsive-th{
    display:block
}
table:not(.table-layout-static) thead,table:not(.table-layout-static) tr td:empty,table:not(.table-layout-static) tr th:empty{
    display:none
}
table:not(.table-layout-static) tr td,table:not(.table-layout-static) tr th{
    border:none!important
}
table:not(.table-layout-static) tr td.has-responsive-th,table:not(.table-layout-static) tr th.has-responsive-th{
    text-align:left;
    padding-left:52%
}
table:not(.table-layout-static) tr td:not(:last-of-type),table:not(.table-layout-static) tr th:not(:last-of-type){
    padding-bottom:0
}
table:not(.table-layout-static) tr:not(:last-of-type) td:last-of-type,table:not(.table-layout-static) tr:not(:last-of-type) th:last-of-type{
    border-bottom:1px solid #e6e9ee!important
}
table:not(.table-layout-static) tfoot tr td:first-of-type,table:not(.table-layout-static) tfoot tr th:first-of-type{
    border-top:2px solid #e6e9ee!important
}
table:not(.table-layout-static).table-layout-domain tr td:nth-of-type(1),table:not(.table-layout-static).table-layout-domain tr td:nth-of-type(2),table:not(.table-layout-static).table-layout-domain tr td:nth-of-type(3),table:not(.table-layout-static).table-layout-domain tr td:nth-of-type(4){
    width:auto;
    text-align:center
}
table:not(.table-layout-static).table-layout-product-comparison tr.product-header td{
    padding-left:0;
    text-align:center
}
table:not(.table-layout-static).table-layout-product-comparison tr.product-header td span.responsive-th{
    display:none
}
table:not(.table-layout-static).table-layout-product-comparison tr.product-feature-group td:first-of-type{
    padding:15px;
    border-bottom:1px solid #e6e9ee
}
table:not(.table-layout-static).table-layout-product-comparison tr.product-feature-group td:not(:first-of-type){
    display:none
}
.tab-group.tab-group-switch-style>ul{
    border-radius:12px
}
.tab-group.tab-group-switch-style>ul li{
    border-radius:6px
}
.tab-group.tab-group-switch-style>ul li:not(:first-of-type){
    margin-left:0;
    margin-top:6px
}

.tab-group:not(.tab-group-switch-style) .tab-item .tab-item-inner{
    border-bottom-width:0;
    border-radius:0
}
.tab-group:not(.tab-group-switch-style) .tab-item:not(.active):last-of-type .tab-item-title,
.tab-group:not(.tab-group-switch-style) .tab-item.active:last-of-type .tab-item-inner{
    border-bottom-width:2px;
    border-bottom-left-radius:12px;
    border-bottom-right-radius:12px
}
.tab-group:not(.tab-group-switch-style)>ul{
    display:none
}
.tab-group.tab-group-switch-style>ul,
.tab-group.tab-group-switch-style>ul li,
.tab-group:not(.tab-group-switch-style) .tab-item .tab-item-title{
    display:block
}
.blog-comment-group.blog-comment-group-center>h3 a,
.blog-comment-form.blog-comment-form-center>h3 a{
    float:none;
    margin-top:0
}
.column_row{
    display:block!important
}
.column_row>.column-20,
.column_row>.column-25,
.column_row>.column-30,
.column_row>.column-33,
.column_row>.column-40,
.column_row>.column-50,
.column_row>.column-60,
.column_row>.column-66,
.column_row>.column-70,
.column_row>.column-75,
.column_row>.column-80,
.column_row>.column-flex{
    width:auto
}
.network-map ul li span.label-top-left,
.network-map ul li span.label-top-right,
.network-map ul li span.label-bottom-left,
.network-map ul li span.label-bottom-right{
    display:none
}

#content>.content_row .contentHeader.contentHeader-align-left,
#content>.content_row .contentHeader.contentHeader-align-right,
#content>.content_row .contentHeader.contentHeader-large.contentHeader-align-left p,
#content>.content_row .contentHeader.contentHeader-large.contentHeader-align-right p{
    text-align:center;
    margin-left:auto;
    margin-right:auto
}

#content>.content_row .column_row>.content-sidebar-left:first-child,
#content>.content_row .column_row>.content-sidebar-right:first-child{
    min-width:0!important;
    padding:15px 15px 30px 15px!important;
    margin:0 0 15px 0!important
}

#content>.content_row .column_row>.content-sidebar-left:first-child::before,
#content>.content_row .column_row>.content-sidebar-right:first-child::before{
    z-index:-1;
    bottom:-1px;
    left:-15px!important;
    right:-15px!important;
    border-bottom:2px solid #e6e9ee;
    border-left:none
}

#content>.content_row .column_row>.content-sidebar-left:last-child,
#content>.content_row .column_row>.content-sidebar-right:last-child{
    min-width:0!important;
    padding:30px 15px 15px 15px!important;
    margin:15px 0 0 0!important
}

#content>.content_row .column_row>.content-sidebar-left:last-child::before,
#content>.content_row .column_row>.content-sidebar-right:last-child::before{
    z-index:-1;
    top:-1px;
    left:-15px!important;
    right:-15px!important;
    border-top:2px solid #e6e9ee;
    border-left:none
}

#content>.content_row.content_row-split-left::after,
#content>.content_row.content_row-split-right::after{
    top:300px;
    left:0;
    right:0
}

#content>.content_row.content_row-split-left .container:not(.container-full-width),
#content>.content_row.content_row-split-left .container.container-full-width,
#content>.content_row.content_row-split-right .container:not(.container-full-width),
#content>.content_row.content_row-split-right .container.container-full-width{
    width:100%;
    -ms-flex-preferred-size:100%;
    flex-basis:100%;
    margin-top:300px
}

#content>.content_row.content_row-split-left .content-background,
#content>.content_row.content_row-split-right .content-background{
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:300px
}

#content>.content_row.content_row-split-left .content-background.content-background-scroll,
#content>.content_row.content_row-split-right .content-background.content-background-scroll{
    top:-60px;
    height:420px
}

}

@media(max-width:768px){
    .product-slider:not(.product-slider-small){
    padding:28px
}
.product-slider:not(.product-slider-small)>ul li{
    width:100%!important;
    text-align:center;
    margin:0
}
.product-slider:not(.product-slider-small)>ul li:last-of-type{
    margin-top:15px!important;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
.product-slider:not(.product-slider-small)>ul li:last-of-type .price,
.product-slider:not(.product-slider-small)>ul li:last-of-type .button{
    -ms-flex-preferred-size:100%;
    flex-basis:100%
}
.product-slider:not(.product-slider-small)>ul li:last-of-type .price{
    text-align:center;
    margin:0
}
.product-slider:not(.product-slider-small)>ul li:last-of-type .price:not(:only-child){
    margin:0 0 15px 0
}
.pagination>li:not(.active):not(:first-of-type):not(:last-of-type),
#content>.content_row.content_row_clouds::before,
#content>.content_row.content_row_clouds::after{
    display:none
}

#content>.content_row .contentHeader,
#content>.content_row .contentHeader.contentHeader-large p{
    max-width:100%
}

#content>.content_row .content-slider>i{
    display:none
}


}


@media(max-width:768px),(max-height:640px){
    body{
    padding-top:80px
}
body.touch-device 
#content>.content_row .content-slider>i.slide-previous,body.touch-device 
#content>.content_row .content-slider>i.slide-next,body.touch-device #gallery-overlay-previous,body.touch-device #gallery-overlay-next{
    display:none!important
}
body.touch-device #gallery-overlay ul li{
    padding:0
}
#header,#nav-overlay{
    padding:10px 0
}
#nav-overlay nav{
    margin:0 -30px
}
#nav-overlay nav .nav-primary{
    margin-top:10px;
    border-left:0;
    border-right:0;
    border-radius:0
}
#header .logo .logo-text,#nav-overlay .logo .logo-text{
    font-size:28px
}
#notification p{
    font-size:14px;
    line-height:26px
}

#content>.content_row .contentHeader.contentHeader-large h1{
    font-size:42px;
    line-height:52px;
    letter-spacing:normal
}

#content>.content_row .contentHeader.contentHeader-large h2{
    font-size:32px;
    line-height:42px
}

#content>.content_row .contentHeader.contentHeader-large h3{
    font-size:24px;
    line-height:34px
}

#content>.content_row .contentHeader.contentHeader-large p{
    font-size:18px;
    line-height:30px
}

#content>.content_row .content-slider>ul{
    bottom:30px
}

#content>.content_row .content-slider .content-slider-inner .slide{
    padding:60px 0
}

#content>.content_row .content-slider.content-slider-has-nav .content-slider-inner .slide{
    padding-bottom:100px
}

#content>.content_row .content-slider .content-slider-inner .slide .contentHeader.contentHeader-large{
    margin-bottom:30px
}

#content>.content_row .content-slider .content-slider-inner .slide .contentHeader.contentHeader-large p{
    margin-top:20px
}


}


@media(max-width:640px){
    .product-configurator .configurator-group,
    .product-configurator .configurator-order{
    display:block;
    text-align:center
}
.product-configurator .configurator-group .value:not(:only-child){
    margin-right:0
}
.product-configurator .configurator-order button{
    width:100%
}
.product-configurator .configurator-order .configurator-total:not(:only-child){
    margin-right:0;
    margin-bottom:15px;
    text-align:center
}

}

@media(max-width:480px){
    #header .logo .logo-text small,#nav-overlay .logo .logo-text small{
    display:none
}

}

@media(-webkit-min-device-pixel-ratio:2),(min-resolution:192dpi),(min-resolution:2dppx){
    
    #content>.content_row.content_row_clouds::before{
        background-image:url(img/layout/clouds-gray-left@2x.png)
    }

#content>.content_row.content_row_clouds::after{
    background-image:url(img/layout/clouds-gray-right@2x.png)
}

#content>.content_row.content_row-gray.content_row_clouds::before,
#content>.content_row.content_row-dark.content_row_clouds::before,
#content>.content_row.content_row-color.content_row_clouds::before,
#content>.content_row.content_row-gradient.content_row_clouds::before{
    background-image:url(img/layout/clouds-light-left@2x.png)
}

#content>.content_row.content_row-gray.content_row_clouds::after,
#content>.content_row.content_row-dark.content_row_clouds::after,
#content>.content_row.content_row-color.content_row_clouds::after,
#content>.content_row.content_row-gradient.content_row_clouds::after{
    background-image:url(img/layout/clouds-light-right@2x.png)
}

}

.tabs{
    font-family:arial;
    margin:0 auto;
    width:70%;
    text-align:center;
    margin-top:15vh;
  }
  
  img{
    width:300px;
  }
  
  .tab-buttons{
    margin-bottom:50px;
    color : #fff;
  }
  
  button{
    margin-right:15px;
    font-size:20px;
    background:transparent;
    border:none;
    outline:none;
    padding:10px 20px ;
    cursor:pointer;
    color:rgba(0,0,0,.4);
    transition:all ease-in-out .2s;
    border-bottom:2px solid transparent;
    
  }
  .active{
    border-bottom:2px solid #42b3f4;
   
    color:green;
    background-color: black
  }
